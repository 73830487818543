.h-fc {
  height: fit-content !important;
}

.w-fc {
  width: fit-content !important;
}

.color-w {
  color: #fff !important;
}

.color-r {
  color: #b92121 !important;
}

.color-r-h:hover {
  transition: color 0.2s;
  color: #b92121 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.w-fc {
  width: fit-content !important;
}

// settings
.settings-twitter-accounts {
  .card-header {
    padding: 1.5rem 2.2rem 1.25rem;
  }
}

// auth button
.auth-button {
  cursor: pointer;
  margin-left: 1rem;
  background-color: #fff;
  padding: 0px 15px 1px;
  height: 40px;
  border-radius: var(--w3m-button-border-radius);
  color: var(--w3m-accent-fill-color);
  background-color: var(--w3m-accent-color);
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;

  img {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  &:hover {
    background-color: rgb(46, 182, 58);
  }
}

.authed-status {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;

  img {
    margin-left: 8px;
    width: 30px;
    height: 30px;
  }
}

.not-authed-msg {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.14);
}

.not-selectable {
  user-select: none;
}

.col-green {
  color: rgb(22, 184, 30) !important;
}

.col-red {
  color: rgb(197, 26, 26) !important;
}

.col-white {
  color: #ffffff !important;
}

.ml-1 {
  margin-left: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.twitter-auth-url-container {
  margin-bottom: 20px;
  .twitter-auth-instructions {
    // margin-top: 20px;
    margin-bottom: 12px;
  }
  .twitter-auth-url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    color: $primary;
  }
}

.col-twitter-blue {
  color: #3eb5ff !important;
}

.col-discord-purple {
  color: #868fff !important;
}

.col-g {
  color: $d-border !important;
}

.col-white {
  color: $white !important;
}

.br-50 {
  border-radius: 50% !important;
}

.border-green {
  border: 0.15rem solid $d-border !important;
}

.swal-text {
  text-align: center;
}

.collection-widget {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  background-color: $dark-sb !important;

  .card-body {
    padding: 1.25rem;
  }
  .collection-img {
    width: 100%;
    height: auto;
    max-width: 80px;
    border: 3px solid #fff;
  }

  .eth-logo {
    margin-right: 7px;
  }
}

.bg-deepblue {
  background-color: #1f2577 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-04 {
  margin-top: 0.4rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.bot-header-badges {
  .badge {
    font-size: 0.82rem;
  }
}

.ml-auto {
  margin-left: auto;
}

.w-100 {
  width: 100%;
}

.max-w-200px {
  max-width: 200px;
}

.max-w-300px {
  max-width: 300px;
}

.max-w-400px {
  max-width: 400px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-600px {
  max-width: 600px;
}

.max-w-700px {
  max-width: 700px;
}

.max-w-1000px {
  max-width: 1000px;
}

.invalid-collection-msg {
  font-size: 1rem;
  text-align: center;
}

.opacity-0 {
  opacity: 0 !important;
}

.z-100 {
  z-index: 100 !important;
}

.twitter-share-btn-sm {
  min-height: 20px;
}

.emoji-toggle {
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 0.875rem;

  cursor: pointer;

  .emoji-popup {
    position: absolute;
    bottom: -425px;
    z-index: 9;
    &.down {
      bottom: -425px;
    }
    &.up {
      bottom: 40px;
    }
  }
}

@media screen and (max-width: 575px) {
  .emoji-toggle {
    display: none;
    .emoji-popup {
      // position: absolute;
      // width: 100%;
      // max-width: 100%;
      // min-width: 100%;
      // left: 50%;
      // bottom: auto;
      // right: auto;
      // transform: translateX(-50%);
    }
  }
}

.sidebar-sub-active {
  background-color: #f0f8ff26;
  border-radius: 8px;
}

.twitter-auth-modal {
  .instructions-text {
    font-size: 1rem;
    margin-bottom: 1rem;
    a {
      color: $primary;
      text-decoration: underline;
      white-space: nowrap;
      i {
        margin-right: 8px;
        margin-left: 8px;
      }

      &:hover {
      }
    }
  }
}

.modal-dialog {
  max-width: 500px;
}

.col-danger {
  color: $danger;
}

.bg-discord {
  background-color: $discord !important;
}

.bg-data {
  background-color: $data !important;
}

.nav-submenu-icon {
  margin-right: 9px;
}

[data-sidebarbg="color_4"][data-theme-version="dark"]
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sidebarbg="color_4"] .menu-toggle .deznav #menu li > ul {
  background: #000000 !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="modern"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-sidebar-style="compact"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"]
  .deznav
  .metismenu
  li
  ul,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"]
  .deznav
  .metismenu
  li
  ul {
  background-color: #000000;
}

.col-b {
  color: #000000 !important;
}

.color-b {
  color: #000000 !important;
}

.text-underline {
  text-decoration: underline;
}
