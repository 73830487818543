.bot-downtime-msg {
  background-color: #a01111;
  color: #fff;
  padding: 20px;
  padding-left: 36px;
  padding-right: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  margin: auto;
}

.inline-btn {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.handled-btn:not(.Mui-disabled) {
  background-color: #21b50e;

  &:hover {
    background-color: #297b1e;
  }
}

.delete-btn:not(.Mui-disabled) {
  background-color: #b50e0e;

  &:hover {
    background-color: #7b1e1e;
  }
}

.option-value {
  border-radius: 6px;
  background-color: #3b8abe;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 4px;

  &.col-w {
    color: #fff;
  }
}

.landmark-img {
  width: 100%;
  max-width: 200px;
  margin: auto;
  text-align: center;
  margin-bottom: 17px;
}

.mr-10 {
  margin-right: 10px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.tooltip.show {
  opacity: 0.97;
}

.col-approved {
  color: #159f06;
}

.col-waitlisted,
.col-tentative {
  color: #ab7000;
}

.col-denied,
.col-not-attending {
  color: #d00;
}

.col-pending {
  color: #656565;
}

.totals-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: 12px;
  justify-content: center;
  align-items: flex-end;

  .total-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 22px;
    margin-left: 4px;

    // &:first-child {
    //   align-self: flex-start;
    // }

    .total-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      width: 100%;
      &:first-child {
        font-weight: 700;

        .value {
          margin-left: 14px;
        }

        &.option {
          width: 100px;
          align-items: flex-end;
          .value {
            margin-left: 0;
            // margin-left: 5px;
          }
        }
      }

      &:not(:first-child) {
        font-size: 11px;
      }

      .field {
        text-align: left;
      }

      .value {
        text-align: right;
      }
    }
  }

  .total-col {
    display: flex;
    align-items: center;
  }
}

.filters-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  .filter-btn {
    margin-top: 0.25rem;
    font-size: 11px;
    line-height: 1;
    padding: 5px;
    height: 29px;
    // margin-right: 6px;
    // margin-bottom: 6px;
    max-width: 100px;
    background-color: #0072ff !important;
    color: #fff !important;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.625rem;
  }
  .filter-range-price-input {
    display: flex;
    width: 100%;
    margin: 30px 0 35px;

    .filter-range-field {
      display: flex;
      width: 100%;
      height: 45px;
      align-items: center;

      span {
        font-size: 15px;
      }

      .filter-range-min-input,
      .filter-range-max-input {
        width: 100%;
        height: 100%;
        outline: none;
        border: 1px solid #999;
        border-radius: 5px;
        text-align: center;
        font-size: 18px;
        margin-left: 12px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }
      }
    }
  }

  .filter-range-seperator {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
  }

  .filter-range-slider {
    height: 5px;
    position: relative;
    background-color: #ddd;
    border-radius: 5px;

    .filter-range-progress {
      position: absolute;
      height: 100%;
      left: 5%;
      right: 65%;
      background-color: #4070f4;
      border-radius: 5px;
    }
  }

  .filter-range-range-input {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 5px;
      top: -5px;
      pointer-events: none;
      background: none;
      appearance: none;
      accent-color: #4070f4;

      &::-webkit-slider-thumb {
        height: 15px;
        width: 15px;
        background-color: #4070f4;
        border-radius: 50%;
        pointer-events: auto;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.filters-disabled {
  .filters-row {
    opacity: 0.22;
  }
}

.filters-enable-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;

  .enable-filters-label {
    font-weight: 500;
    font-size: 14px;
  }
}

.filter-input {
  font-size: 11px;
  line-height: 1;
  padding: 5px;
  height: 29px;
  margin-right: 6px;
  margin-bottom: 6px;
  max-width: 100px;
}

.filter-option {
  display: flex;
  height: 29px;
  margin-right: 6px;
  margin-bottom: 6px;
  margin-top: 0.25rem;

  .option-title {
    font-size: 11px;
  }

  .input-group {
    height: 29px;

    .input-group-text {
      height: 29px;
      background-color: #7171711a;
      color: #474747;
    }
  }

  select.form-control {
    line-height: 1;
    font-size: 11px;
    height: 29px;
  }

  option {
    height: 29px;
    font-size: 11px;
    line-height: 1;
  }
}

.campaign-dashboard-table {
  td {
    max-width: 320px;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    white-space: normal !important;
  }
}

.centered-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.event-image-img {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 100px;
}

.events-owners-heading {
  font-size: 24px;
  font-weight: 700;
}
.events-owners-subheading {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 14px;
}

.event-owners-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > * {
    margin-right: 8px; // Adjust the value to set the desired space between containers
  }

  > *:last-child {
    margin-right: 0; // Remove margin from the last container to avoid trailing space
  }

  .event-owner-container {
    padding: 8px;
    padding-left: 14px;
    padding-right: 14px;
    max-height: fit-content;
    background-color: #010b134d;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: fit-content;

    &.selectable {
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: #60b7ff;
      }
      &.selected {
        background-color: #60b7ff;
      }
    }

    .event-img {
      width: 60px;
      margin: auto;
      margin-bottom: 5px;
    }

    .event-title {
      font-size: 16px;
      font-size: 600;
    }
    // .duplicate-btn {
    //   margin-top: 8px;
    //   font-size: 12px;
    //   color: #05558d;
    //   cursor: pointer;
    //   &:hover {
    //     text-decoration: underline;
    //   }
    // }

    .event-description {
      font-size: 12px;
      font-size: 500;
    }

    .event-owner-outer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 4px;
      margin-top: 6px;
      .event-owner {
        background-color: #05558d;
        border-radius: 4px;
        padding-left: 6px;
        padding-right: 6px;
      }

      .event-owner-remove {
        cursor: pointer;
        transform: translateY(4px);
        &:hover {
          color: $danger;
        }
      }
    }
  }
  .event-owner-add {
    opacity: 0.4;
    transition: opacity 0.3s;
    margin-top: 8px;
    cursor: pointer;
    &:hover {
      color: $success;
      opacity: 1;
    }
  }
}

.light-bg {
  background-color: #98b9d3;
}

.global-dash-wrapper {
  .custom-question-container {
    padding-left: 60px;
  }
  .close-registrations-txt {
    color: #b30909;
  }
  .btn.btn-secondary {
    background-color: #0072ff;
    border: none;
    display: block;
  }
  .btn.btn-danger {
    background-color: #d72c2c;
    border: none;
    margin-left: 10px;
    padding: 5px 10px;
  }
  .divider {
    height: 1px;
    background-color: #0073ff2c;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .existing-logos-container {
    margin-bottom: 20px;
    border-radius: 22px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    background-color: #6ec5ff36;
    .existing-logos-outer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      .existing-logo {
        max-width: 156px;
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.15s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .deleted-col {
    color: #d90707;
  }
  .undelete-rsvps-btn {
    background-color: #cf7301;
    border-color: #cf7301;

    &:hover {
      background-color: #9c5a0a;
      border-color: #9c5a0a;
    }
  }
  tr.deleted {
    background-color: #e314143d;
  }
  th {
    white-space: normal !important;
    word-wrap: break-word;
  }
  td.wrap {
    word-wrap: break-word;
    white-space: normal !important;
  }
  .email-options-btn {
    margin-bottom: 0 !important;
  }
  .email-options-outer-container {
    background-color: #fff;
    border-radius: 8px;
  }
  .confirm-email-editor-container {
    width: 100%;
    max-width: 1020px;
    padding: 16px;
    padding-top: 16px;
    margin: auto;
    text-align: center;
    // border: #9b9b9b 2px solid;
    border-radius: 8px;

    .save-changes-btn {
      margin-bottom: 12px;
    }

    .title {
      font-size: 17px;
      font-weight: 500;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 14px;
      color: #8d8d8d;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 14px;
    }

    .template-form {
      text-align: left;
      padding: 18px;
      padding-top: 8px;
      padding-bottom: 8px;

      .textarea {
        line-height: 1.2;
        font-size: 11px;
      }

      .no-events-msg {
        font-size: 12px;
        text-align: center;
      }
    }

    .viewer {
      width: 100%;
      text-align: left;
      border: #9b9b9b 2px solid;
      border-radius: 8px;
      padding: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.4;

      .subject {
        font-size: 14px;
        font-weight: 700;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .divider {
        height: 2px;
        width: 100%;
        background-color: #d2d2d2;
        margin-bottom: 10px;
      }

      .body {
        font-size: 14px;

        p {
          line-height: 1.4;
        }

        a {
          color: revert !important;
        }
      }
    }
  }

  .no-rsvps-msg {
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .table {
    color: #575757;
  }
  .btn-primary {
  }
  .page-titles {
    background: #004f85;
  }
  .not-authed-msg {
    color: #000;
    padding: 0;
    margin: 0.5rem;
  }
  .form-control {
    background-color: #ffffff !important;
    border-color: #0072ff !important;
    color: #000 !important;
  }

  .btn-primary {
    background-color: $lm !important;
    border-color: transparent !important;
    color: #fff !important;

    &:hover {
      background-color: #{darken($lm, 10)} !important;
    }
  }

  .btn-grey {
    background-color: #a1a1a1 !important;
    border-color: transparent !important;
    color: #fff !important;

    &:hover {
      background-color: #{darken(#a1a1a1, 10)} !important;
    }
  }

  .bot-table.card {
    background-color: #bad7eb !important;
    &.white-bg {
      background-color: #fff !important;
    }
    color: #000;
  }

  .event-owners-wrapper .event-owner-container {
    background-color: #d3ebff;
    color: #000;
  }
  .event-owners-wrapper .event-owner-container .event-owner-outer .event-owner {
    background-color: #2488cd;
    color: #fff;
  }

  .table-wrapper {
    background-color: #fff;
    margin: 16px;
    padding: 8px;
    border-radius: 8px;
  }

  .form-control.input-search {
    height: 38px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .input-search {
    height: 38px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #eee;
    padding: 0 8px;
  }

  .filtering-table thead th:first-child {
    width: 70px;
  }
  .table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #eeeeee;
  }

  .filter-pagination {
    display: inline-block;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 5px 5px;
  }
  .filter-pagination .previous-button,
  .filter-pagination .next-button {
    font-size: 16px;
    border: 0 !important;
    width: auto;
    padding: 14px 20px;
    margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
    color: #9568ff;
  }
  .filter-pagination button:hover {
    background: #9568ff;
    color: #fff;
  }
  .filter-pagination button[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  .filter-pagination button[disabled]:hover {
    background: #9568ff;
    opacity: 0.5;
    color: #fff;
  }
  .table-index {
    margin-top: 8px;
  }
  .table-index input[type="number"] {
    background: #f0f0f0 !important;
    color: #969ba0;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
  }
  @media only screen and (max-width: 575px) {
    .filter-pagination .previous-button,
    .filter-pagination .next-button {
      padding: 8px 10px;
      font-size: 14px;
    }
  }

  .table-card {
    margin: 20px;
  }

  .bot-table tbody tr:hover {
    background-color: #ebedee;
    cursor: default;
  }

  .email-template-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  .user-container-outer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pfp-img {
      border-radius: 50%;
      margin-right: 10px;
      width: 46px;
    }
  }

  .user-container {
    display: flex;
    flex-direction: column;

    .deleted {
      color: #d90707;
    }

    .name {
      font-weight: 700;
    }

    .email {
      position: relative;
      font-weight: 500;
    }

    .job {
    }
  }

  .clipboard-icon {
    cursor: pointer;
    margin-left: 4px;
    font-size: 0.7rem;

    &:hover {
      color: #17d2ff; /* Set the color you want when hovering over the icon */
    }
  }

  .bg-success {
    background-color: #00e3b4;
  }

  .selected-rsvps-controls-container {
    background-color: #e0eeff;
    /* border: 1px solid #d9d9d9; */
    border-radius: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 65px;
    padding-right: 65px;
    width: fit-content;
    margin: auto;

    .selected-display {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }
  }

  .not-requested-btn {
    position: relative;
    width: fit-content;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .badge-wrapper {
    position: relative;
    width: fit-content;

    .status-badge {
      cursor: pointer;
      color: #000;
    }
    .final-badge-wrapper {
      position: absolute;
      top: -35%;
      right: -18%;
      .final-badge {
        font-size: 10px;
        color: #fff;
        background-color: rgb(64, 127, 209);
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 4px;
      }
    }

    &.inline {
      display: inline-block;
      font-size: 12px;
      margin-left: 3px;
      // margin-right: 3px;
    }
  }

  .or-not-requested-title {
    // font-size: 12px;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-bottom: 12px;
  }

  .badge-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .option-update-history {
    text-align: center;
    width: 100%;
    margin: auto;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .update-line {
      margin-top: 8px;
    }
    .update-line-time {
      font-size: 10px;
    }
  }

  .popup-container {
    position: fixed;
    display: block;
    z-index: 10;
    background-color: #fff;
    padding: 14px;
    border-radius: 8px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }
  .popup-container.show {
    transform: scale(1);
  }

  .bulk-email-btn {
    &.disabled {
      background-color: #000000 !important;
      opacity: 0.45;
    }
  }

  .progress-bar-container {
    width: 100%;
    max-width: 552px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &.zoom-dash {
    .loading-container-big {
      width: 100%;
      max-width: 80px;
      margin: auto;
      text-align: center;
    }

    .loading-text {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
      margin-top: 22px;
      color: #000;
    }

    .btn-primary.get-details-btn {
      width: 120px;
      max-width: 120px;
      height: 45px;
      max-height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .loading-container {
        width: 40px;
        max-width: 45px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .result-display {
      width: 100%;
      max-width: 1020px;
      padding: 16px;
      padding-top: 16px;
      margin: auto;
      text-align: center;
      // border: #9b9b9b 2px solid;
      border-radius: 8px;

      .save-changes-btn {
        margin-bottom: 12px;
      }

      .title {
        font-size: 17px;
        font-weight: 500;
      }

      .description {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 14px;
        color: #8d8d8d;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 14px;
      }

      .template-form {
        // text-align: left;
        // padding: 18px;
        // padding-top: 8px;
        // padding-bottom: 8px;

        .textarea {
          line-height: 1.2;
          font-size: 11px;
        }

        .no-events-msg {
          font-size: 12px;
          text-align: center;
        }
      }

      .viewer {
        position: relative;
        width: 100%;
        text-align: left;
        border: #0072ff 1px solid;
        background-color: #fff;
        border-radius: 8px;
        padding: 18px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.4;

        .clipboard {
          position: absolute;
          top: 0;
          right: 8px;
          font-size: 20px;
          cursor: pointer;
          color: #002c79;
          transition: color 0.2s;
          &:hover {
            color: #007bff;
          }
        }

        .subject {
          font-size: 14px;
          font-weight: 700;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .divider {
          height: 2px;
          width: 100%;
          background-color: #d2d2d2;
          margin-bottom: 10px;
        }

        .body {
          font-size: 14px;
          background-color: white;
          font-family: Arial;
          font-size: 10px;

          p {
            line-height: 1.4;
          }

          a {
            color: revert !important;
          }
        }
      }
    }
  }

  &.invoice-dash {
    .error-msg {
      width: 100%;
      font-size: 14px;
      text-align: center;
      margin: auto;
      margin-top: 100px;
      a {
        color: #0072ff;
        &:hover {
          color: #1763c1;
        }
      }

      &.thank-you-msg {
        margin-top: 50px;
      }
    }
    .qb-customer-select-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      select {
        width: 300px;
        margin: auto !important;
        margin-top: 10px !important;
      }

      button {
        width: 140px;
        margin: auto !important;
        margin-top: 20px !important;
      }
    }
    .example-text-alt {
      font-style: italic;
      opacity: 0.78;
      font-size: 12px;
    }
    .qb-settings {
      display: flex;
      justify-content: center;
      align-items: end;
      margin-bottom: 50px;

      .client-name {
        font-weight: 700;
        font-size: 16px;
      }

      .qb-settings-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:not(:last-child) {
          margin-right: 23px;
        }
        .qb-settings-title {
          font-size: 18px;
        }

        .qb-settings-inputs {
          display: flex;
          input {
            width: 66%;
            margin-right: 8px;
          }

          button {
            width: 34%;
          }
        }
      }
    }
    .inv-link {
      color: #0072ff;
      &:hover {
        color: #1763c1;
      }
    }
    .table tbody tr td {
      white-space: normal;
      max-width: 180px;
    }

    .quickbooks-select {
      word-wrap: break-word;
      max-width: 165px;
    }

    .export-csv-btn {
      width: fit-content;
    }

    .export-csv-text {
      text-decoration: underline;
      color: #0072ff;
      cursor: pointer;
      filter: brightness(1);

      &:hover {
        transition: filter 0.1s;
        filter: brightness(0.7);
      }
    }

    .handled-btn {
      background-color: #159519 !important;
    }

    .form-check-input:checked,
    .checkbox-success .form-check-input:checked,
    input#checkAll.form-check-input:checked {
      background-color: #0072ff;
      border-color: #0072ff;
    }

    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23FFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
    }

    tr.handled {
      background-color: #10ff001c;
    }
  }

  &.dynamic-events {
    .input-group-text {
      /* background-color: var(--rgba-primary-1); */
      background-color: #e9ecef;
      color: #000000;
    }

    .btn.btn-danger {
      background-color: #d72c2c;
      border: none;
      margin-left: 10px;
      padding: 5px 10px;
    }

    .btn.btn-secondary {
      background-color: #0072ff;
      border: none;
      display: block;
    }

    .save-event-btn {
      margin: auto;
      font-size: 20px;
    }

    .page-preview-btn {
      min-width: 205px;
      font-size: 14px;
      background-color: #d57600 !important;
      &:hover {
        background-color: #b86b0d !important;
      }
    }

    .divider {
      height: 1px;
      background-color: #0073ff56;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    // .header-logo-img {
    //   max-width: 156px;
    // }

    .header-logo-container {
      padding: 0.7rem;
    }

    .controls-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 8px;

      .scale-container {
        position: relative;

        margin-right: 6px;

        label {
          position: absolute;
          top: -20px;
          text-align: center;
          width: 100%;
        }
      }

      .yOffset-container {
        position: relative;

        .yOffset-spinner {
          width: 80px;
        }

        label {
          position: absolute;
          top: -20px;
          text-align: center;
          width: 100%;
        }
      }
    }

    .existing-logos-container {
      margin-bottom: 20px;
      border-radius: 22px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 17px;
      padding-bottom: 17px;
      background-color: #6ec5ff36;
      .existing-logos-outer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        .existing-logo {
          max-width: 156px;
          opacity: 0.7;
          cursor: pointer;
          transition: all 0.15s ease;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .ref-url {
      font-size: 12px;
    }

    .example-text {
      margin-bottom: 10px;
    }

    .p-relative {
      position: relative;
    }

    .remove-ref-url-btn {
      position: absolute;
      right: -83px;
      top: 8px;
    }

    .form-control {
      &.page-url,
      &.owner-email,
      &.ref-name {
        &.invalid {
          border-color: #d72c2c !important;
        }
      }

      &.scale-spinner {
        width: 68px;
        text-align: center;
      }
    }

    .page-url-invalid-msg {
      color: #d72c2c;
      margin-top: 5px;
    }

    .dynamic-event-preview-container {
      width: 100%;
      .ed-wrapper {
        margin: 20px;
        border: 2px solid #0072ff66;
      }
    }

    .dynamic-check-option-container {
      margin-bottom: 5px !important;
      margin-top: 20px;
    }

    .form-check-input:checked {
      background-color: #0072ff;
      border-color: #0072ff;
    }

    .form-check-input:focus {
      border-color: #0072ff;
      box-shadow: none;
    }

    .form-check-input:checked[type="checkbox"] {
      // white
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
    }

    .event-owner-container {
      .ref-urls-title {
        text-align: left;
        margin-top: 20px;
        width: 100%;
      }

      .ref-urls-container {
        display: flex;
        flex-direction: column;

        .ref-inner-container {
          text-align: left;
          .ref-urls-url {
            font-size: 14px;
          }

          .ref-urls-name {
            margin-right: 8px;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }

    .btn-view-rsvps {
      // background-color: #099020;
      background-color: #1ca734;
      border-color: #1ca734;

      &:hover {
        background-color: #1e8e30;
      }
    }
  }

  &.sweeper {
    .centered-row {
      display: flex;
      justify-content: center;
    }
    .save-sweeper-btn {
    }
  }
}

.modal-event-header-msg {
  font-size: 16px;
}

.modal-view-landing-text {
  font-size: 16px;
  a {
    color: #0072ff;
    &:hover {
      color: #1a65c0;
    }
  }
}

.modal-view-event-close-btn {
  background-color: #0072ff;
  border: none;
  display: block;

  &:hover {
    background-color: #0b52a9;
  }
}

a.blue-link {
  color: #0072ff;
  &:hover {
    color: #1b61b6;
  }
}

.blue-text {
  color: #0072ff;
}

.landmark-sign-in {
  width: 100%;
  max-width: 360px;
  margin: auto;

  .sign-up-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
  }

  .header-text {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 12px;
  }

  .input-primary .input-group-text {
    background-color: $lm;
  }

  .new-account-btn {
    font-size: 15px;
    text-align: center;
    padding: 8px;
    margin-top: -14px;
    color: #2f2f2f;
    cursor: pointer;

    .text {
      text-decoration: underline;
    }
  }

  .existing-accounts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .account {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #e5ecf2;
      transition: 0.15s;
      padding: 8px;
      padding-right: 12px;
      border-radius: 300px;
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: 13px;
      }

      &:not(.disable-hover):hover {
        cursor: pointer;
        background-color: $lm;
        color: #fff;
      }

      .user-icon {
        margin-right: 6px;
        position: relative;
        width: 26px;
        height: 26px;
        background-color: #000;
        color: #fff;
        border-radius: 50%;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .email {
        font-size: 12px;
        line-height: 1;
      }
      .name {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
}

.toltip-popover .tooltip-inner {
  background: #ffffff;
  padding: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border: 0.125rem solid $lm;
  max-width: unset;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: $lm;
}

.confirm-email-popup {
  height: 360px;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // align-items: center;
  z-index: 1000;

  .email-container {
    // margin-top: 20px;
    color: #000000;
    overflow-y: scroll;
    scrollbar-color: #fff #969595;
    height: 100%;
    width: 100%;
    padding: 24px;

    .sender {
      font-size: 12px;
      text-align: left;
      margin: auto;
      width: 100%;
      margin-bottom: 5px;

      .bold {
        font-weight: 500;
        color: #2f2f2f;
      }
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #969595;
      border-radius: 20px;
      // border: 3px solid orange;
    }

    .viewer {
      margin-top: 9px;
      color: #000;
      width: 100%;
      text-align: left;
      border: #9b9b9b 2px solid;
      border-radius: 8px;
      padding: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.4;

      .subject {
        font-size: 14px;
        font-weight: 700;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .divider {
        height: 2px;
        width: 100%;
        background-color: #d2d2d2;
        margin-bottom: 10px;
      }

      .body {
        font-size: 14px;

        p {
          line-height: 1.4;
        }

        a {
          color: revert !important;
        }
      }
    }

    .resend-email {
      cursor: pointer;
      color: #0365e3;
      margin-top: 12px;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.comments-popup {
  height: 360px;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // align-items: center;
  z-index: 1000;

  .add-comment-container {
    /* background-color: white; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    /* width: 300px; */
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 48px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    resize: vertical;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .cancel-button {
      margin-right: 10px;
      background-color: #ccc;
      border: none;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
    }

    .create-button {
      background-color: #007bff;
      border: none;
      color: white;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .horizontal-bar {
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, white 0%, #cfcfcf 50%, white 100%);
  }

  .comments-container {
    // margin-top: 20px;
    color: #464646;
    overflow-y: scroll;
    scrollbar-color: #fff #969595;
    height: 100%;
    width: 100%;

    .no-comments-text {
      font-size: 12px;
      text-align: center;
      margin: auto;
      margin-top: 30px;
      width: 100%;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #969595;
      border-radius: 20px;
      // border: 3px solid orange;
    }

    .comment {
      display: flex;
      align-items: flex-start;
      padding: 10px;

      &.reply {
        padding-left: 44px;
        padding-top: 0;

        .user-icon {
          width: 22px;
          height: 22px;
          .icon {
            width: 18px;
            height: 18px;
          }
        }
      }

      .user-icon {
        width: 28px;
        height: 28px;
        background-color: rgb(119, 119, 119);
        border-radius: 50%;
        margin-right: 6px;
        position: relative;
        background-color: #000;
        color: #fff;
        border-radius: 50%;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .comment-content {
        text-align: left;
        flex: 1;
        font-size: 12px;
        font-weight: 400;

        .comment-header {
          display: flex;
          align-items: center;
          margin-bottom: 1px;

          .commenter-name {
            font-weight: bold;
            margin-right: 5px;
          }

          .comment-date {
            color: #777;
          }
        }

        .comment-text {
          margin-bottom: 3px;
        }

        .reply-button {
          background: none;
          border: none;
          color: #007bff;
          transition: color 0.2s ease;
          cursor: pointer;

          &:hover {
            color: #1f8bff;
          }
        }

        .add-reply-container {
          margin-top: 10px;

          textarea {
            width: 100%;
          }

          .button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            .cancel-button {
              // ... button styles ...
            }

            .create-button {
              // ... button styles ...
            }
          }
        }
      }
    }
  }
}

.headshot-img-table {
  max-width: 100px;
  text-align: center;
  margin: auto;
}

.dl-img-btn {
  padding-top: 8px;
  opacity: 0.6;
  width: 100%;
  max-width: 100px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    opacity: 1;
    color: #00e3b4;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  background-color: #002c79;
}

td.MuiTableCell-root {
  // word-break: "break-all";
  white-space: normal !important;
  cursor: default;
}

// confirmation email cell
.confirm-email-cell-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 116px;

  .awaiting-final-msg {
  }

  .existing-confirm-email {
    cursor: pointer;
    color: #0365e3;
    &:hover {
      text-decoration: underline;
    }
  }

  .not-all-final-msg {
    color: #b47b11;
    font-size: 10px;
    margin-top: 4px;
  }
}

.code-editor {
  textarea {
    &::selection {
      background: #017cff4e;
      color: #000000;
    }
  }
}

.add-edit-btn-container {
  display: flex;
  justify-content: center;

  .create-event-btn {
    margin: 6px;
    margin-bottom: 20px;
    margin-top: 0;
  }
}

.event-form {
  max-width: 500px;
  font-size: 14px;
  margin: auto;
  a {
    color: #0072ff;
  }

  .example-text {
    font-style: italic;
    opacity: 0.78;
    font-size: 12px;
  }

  .options-wrapper {
    background: #0000000d;
    border-radius: 8px;
    padding: 22px;
  }

  .edit-options-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    .option-container {
      padding: 8px;
      padding-left: 14px;
      padding-right: 14px;
      max-height: -moz-fit-content;
      max-height: fit-content;
      background-color: #010b134d;
      color: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      width: -moz-fit-content;
      width: fit-content;
      max-width: 170px;
      text-align: left;
      margin: 6px;
      cursor: pointer;
      transition: all 0.2s;

      &.selected {
        background-color: #4ca9f6;
      }

      &:hover {
        background-color: #60b7ff;
      }

      &.new {
        // background-color: #1272c14d;
        &:hover {
          background-color: #60b7ff;
        }
        padding-top: 12px;
        padding-bottom: 12px;

        .title {
          margin-bottom: 0;
        }
      }

      .title {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 5px;
      }
      .location {
        line-height: 1;
        font-size: 12px;
        color: #e8e8e8;
        max-height: 90px;
        white-space: nowrap; /* Prevent text from wrapping to a new line */
        overflow: hidden; /* Hide any text that overflows the container */
        text-overflow: ellipsis; /* Add the ellipsis (...) */
      }
    }
  }
}

// DeDupe tool
.dedupe {
  .dedupe-form-wrapper {
    width: 100%;
    max-width: 420px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .upload-csv-btn {
      margin: 6px;
      margin-bottom: 8px;
      margin-top: 0;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: 12px !important;

      &.dl-btn {
        margin-top: 8px;
        background-color: #30ac22 !important;
        &:hover {
          background-color: #1f8813 !important;
        }
      }

      &.dl-contacts-btn {
        margin: 0;
      }
    }

    .file-name {
      &.sm {
        font-size: 11px;
      }
    }

    .csv-option-container {
      display: flex;
      justify-content: center;
    }

    .form-section {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 12px;
    }
  }

  .loading-container {
    width: 100%;
    max-width: 68px;
    margin: auto;
  }

  .logo-container {
    width: 100%;
    max-width: 220px;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 16px;
  }

  .bot-table.card {
    // background-color: #a0cbf7 !important;
    margin-top: 75px;
    padding-bottom: 22px;
    max-width: 463px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: #0072ff 0px 0px 0px 3px inset,
      rgb(152, 185, 211) 10px -10px 0px -3px, #0073ffc2 10px -10px,
      rgb(152, 185, 211) 20px -20px 0px -3px,
      rgba(0, 115, 255, 0.431) 20px -20px,
      rgb(152, 185, 211) 30px -30px 0px -3px,
      rgba(0, 115, 255, 0.142) 30px -30px,
      rgb(152, 185, 211) 40px -40px 0px -3px,
      rgba(0, 115, 255, 0.057) 40px -40px;
  }
}

.quickcsv {
  .example-text {
    // font-style: italic;
    opacity: 0.78;
    font-size: 12px;
  }
  .custom-formatting-textarea {
    min-height: 80px;
    resize: vertical;
    line-height: 1.2;
  }
  .custom-formatting-switch {
    margin-top: 20px;
  }
  .custom-formatting-label {
    margin-left: 0.3125rem;
    margin-top: 0.302rem;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .logo-container {
    max-width: 260px;
    transform: translateX(-15px);
  }
  .form-check-input:checked,
  .checkbox-success .form-check-input:checked,
  input#checkAll.form-check-input:checked {
    background-color: #0072ff;
    border-color: #0072ff;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23FFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
  }
  .filters-row {
    &.disabled {
      opacity: 0.22;
    }
  }

  .filters-disabled {
    .filters-row {
    }
  }

  .filters-enable-row {
    .enable-filters-label {
    }
  }

  .filter-input {
  }

  .filter-option {
    .option-title {
    }

    .input-group {
      .input-group-text {
      }
    }

    select.form-control {
    }

    option {
    }
  }

  .result-display {
    width: 100%;
    max-width: 1020px;
    padding: 16px;
    padding-top: 16px;
    margin: auto;
    text-align: center;
    // border: #9b9b9b 2px solid;
    border-radius: 8px;

    .save-changes-btn {
      margin-bottom: 12px;
    }

    .title {
      font-size: 17px;
      font-weight: 500;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 14px;
      color: #8d8d8d;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 14px;
    }

    .template-form {
      // text-align: left;
      // padding: 18px;
      // padding-top: 8px;
      // padding-bottom: 8px;

      .textarea {
        line-height: 1.2;
        font-size: 11px;
      }

      .no-events-msg {
        font-size: 12px;
        text-align: center;
      }
    }

    .viewer {
      position: relative;
      width: 100%;
      text-align: left;
      border: #0072ff 1px solid;
      background-color: #fff;
      border-radius: 8px;
      padding: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.4;

      .clipboard {
        position: absolute;
        top: 0;
        right: 8px;
        font-size: 20px;
        cursor: pointer;
        color: #002c79;
        transition: color 0.2s;
        &:hover {
          color: #007bff;
        }
      }

      .subject {
        font-size: 14px;
        font-weight: 700;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .divider {
        height: 2px;
        width: 100%;
        background-color: #d2d2d2;
        margin-bottom: 10px;
      }

      .body {
        font-size: 14px;
        background-color: white;
        font-family: Arial;
        font-size: 10px;

        p {
          line-height: 1.4;
        }

        a {
          color: revert !important;
        }
      }
    }
  }
}

// seamless bot dashboard
.seamless-bot {
  .ceo-sniper-tasks-section {
    .header-text {
      font-size: 23px;
      font-weight: 600;
      color: #1e4473;
      margin: auto;
      margin-top: 75px;
      text-align: center;
    }

    .tasks-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      .task-outer-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        .arrow {
          font-size: 50px;
          margin-left: 20px;
          margin-right: 20px;
          color: #0072ff;
        }
        .task-container {
          .employee-name-container {
            display: flex;
            padding: 1px;
            padding-left: 10px;
            padding-right: 10px;
            border: 3px solid #0072ff;
            align-items: center;
            justify-content: center;
            position: absolute;
            border-radius: 8px;
            background-color: #fff;
            top: -16px;
            left: 0;

            &.sales-nav {
              background-color: #0072ff;
              color: #fff;
              border: 0;
              top: 0;
              right: 0;
              left: auto;
            }

            .employee-name {
              font-size: 13px;
              text-align: center;
            }
          }
          flex-direction: column;
          position: relative;
          max-width: 580px;
          padding: 24px;
          padding-top: 24px;
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          box-shadow: #0072ff 0px 0px 0px 3px inset, #98b9d3 10px -10px 0px -3px,
            #0073ffc2 10px -10px, #98b9d3 20px -20px 0px -3px;
          border-radius: 8px;

          .error-msg {
            color: #b71414;
            font-size: 16px;
            text-align: center;
            width: 100%;
          }

          .info {
            font-size: 16px;
            line-height: 1.3;
            // margin-bottom: 12px;
            &.highlighted {
              font-weight: 700;
            }
          }

          a {
            color: #0072ff;

            &:hover {
              color: #1661bd;
            }
          }

          .inner-container {
            display: flex;
            align-items: center;
          }

          .pfp {
            margin-right: 10px;
            width: 70px;
            border-radius: 50%;
            border: 2px solid #0072ff;
          }

          .controls {
            .task-btn {
              font-size: 16px;
              padding: 4px;
              padding-left: 10px;
              padding-right: 11px;
              &:not(:last-child) {
                margin-right: 6px;
              }

              &.stop {
                background-color: #b30808 !important;
                &:hover {
                  background-color: #711010 !important;
                }
              }

              &.download {
                background-color: #08b319 !important;
                &:hover {
                  background-color: #158c21 !important;
                }
              }

              .invisiblex {
                opacity: 1;
              }
            }
          }
        }
        .handled-container {
          margin-left: 40px;
          display: flex;
          align-items: center;

          .handled-outer {
            display: flex;
            align-items: center;
          }

          .form-check {
          }

          .handled-label {
            color: #000;
            margin: auto;
            margin-left: 6px;
          }

          .checkbox-success .form-check-input:checked {
            background-color: #0072ff;
            border-color: #0072ff;
          }

          .form-check-input:checked[type="checkbox"] {
            // white
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
          }
        }
      }
    }
  }

  .incomplete-tasks-section {
    .error-container {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 0 !important;

      .left {
        width: fit-content;
        margin-right: 12px;
      }
      .right {
        width: fit-content;
      }

      .error-msg {
        margin-bottom: 2px !important;
        color: #b31a1a;

        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }

    .header-text {
      font-size: 23px;
      font-weight: 600;
      color: #1e4473;
      margin: auto;
      margin-top: 75px;
      text-align: center;
    }

    .tasks-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 463px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      .task-container {
        padding: 16px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: #0072ff 0px 0px 0px 3px inset, #98b9d3 10px -10px 0px -3px,
          #0073ffc2 10px -10px, #98b9d3 20px -20px 0px -3px;
        border-radius: 8px;
        margin-bottom: 32px;
        .info {
          div {
            font-size: 16px;
            margin-bottom: 12px;
            .highlighted {
              font-weight: 700;
            }
          }
        }

        .controls {
          .task-btn {
            font-size: 16px;
            padding: 4px;
            padding-left: 10px;
            padding-right: 11px;
            &:not(:last-child) {
              margin-right: 6px;
            }

            &.stop {
              background-color: #b30808 !important;
              &:hover {
                background-color: #711010 !important;
              }
            }

            &.download {
              background-color: #08b319 !important;
              &:hover {
                background-color: #158c21 !important;
              }
            }

            .invisiblex {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .checkbox-label {
    margin-left: 10px;
    transform: translateY(2px);
    &.on {
      color: #159f06;
    }

    &.off {
      color: #b71414;
    }
  }
  .form-control.disabled,
  .form-label.disabled {
    opacity: 0.5;
  }
  .example-text {
    font-style: italic;
    opacity: 0.78;
    font-size: 12px;
  }
  .sub-logo-text-container {
    display: flex;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: auto;
    max-width: 400px;
  }
  .seamless-bot-form-wrapper {
    width: 100%;
    max-width: 420px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .run-btn {
      margin: 6px;
      margin-bottom: 8px;
      margin-top: 5px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: 13px !important;

      &.disabled-soft {
        opacity: 0.35 !important;
      }

      &.dl-btn {
        margin-top: 8px;
        background-color: #30ac22 !important;
        &:hover {
          background-color: #1f8813 !important;
        }
      }
    }

    .file-name {
      &.sm {
        font-size: 11px;
      }
    }

    .csv-option-container {
      display: flex;
      justify-content: center;
    }

    .form-section {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 12px;
    }
  }

  .loading-container {
    width: 100%;
    max-width: 68px;
    margin: auto;
  }

  .logo-container {
    width: 100%;
    max-width: 420px;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 16px;
  }

  .bot-table.card {
    // background-color: #a0cbf7 !important;
    margin-top: 75px;
    padding-bottom: 22px;
    max-width: 463px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: #0072ff 0px 0px 0px 3px inset,
      rgb(152, 185, 211) 10px -10px 0px -3px, #0073ffc2 10px -10px,
      rgb(152, 185, 211) 20px -20px 0px -3px,
      rgba(0, 115, 255, 0.431) 20px -20px,
      rgb(152, 185, 211) 30px -30px 0px -3px,
      rgba(0, 115, 255, 0.142) 30px -30px,
      rgb(152, 185, 211) 40px -40px 0px -3px,
      rgba(0, 115, 255, 0.057) 40px -40px;
  }
}

.text-left {
  text-align: left;
}

.disabled-text-input {
  background-color: #e9ecef !important;
}

.col-black {
  color: #000000 !important;
}
