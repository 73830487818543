// spottie
.spottie-tweet-example-card {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0 !important;
  transform: translateY(14px);

  .bot-example-card-badge {
    position: absolute;
    font-size: 0.9rem;
    // background-color: $primary;
    // color: #000;
    background-color: #00000000;
    color: $secondary;
    border-radius: 6px;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    // padding-bottom: 0;
    top: -3%;
    left: 2px;

    // transform: rotate(357deg);
    // top: -2%;
    // left: -4px;
  }
}

.bot-header-badges {
  .badge {
    margin-right: 0.4rem;
  }
}

.bot-table {
  .no-userBots-msg {
    text-align: center;
    font-size: 1rem;
  }
  tbody {
    tr {
      &.selected {
        background-color: $dark-sb;
      }
      &:hover {
        background-color: $dark-sb;
        &:not(.cursor-default) {
          cursor: pointer;
        }
      }

      .eth-logo {
        margin-right: 6px;
      }
    }
  }
}

.tweet-automation {
  // background-color: $dark-sb;
  color: $white;
  border-radius: 12px;
  padding: 1rem;

  a {
    color: $primary;
    font-weight: 700;
  }

  .heading {
    text-align: center;
  }

  .not-authed-twitter {
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
  }

  .automation-status-img {
    margin: auto;
  }

  .automation-status-msg {
    margin-top: 0.6rem;
    font-weight: 700;
    &.enabled {
      color: $success;
    }

    &.disabled {
      margin-bottom: 0.4rem;
      color: $danger;
    }
  }

  .automation-disabled-msg {
    margin-top: 0.6rem;
    font-weight: 700;
  }
}

.twitter-auth-select-dropdown {
  font-size: 1rem;
  line-height: 1;

  .twitter-auth-dropdown-item {
    line-height: 1.2;
    padding-right: 1rem;
    .name {
      font-weight: 700;
    }

    .username {
      font-weight: 400;
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }
  }
}

.tweet-designer {
  // background-color: rgb(249, 249, 249);
  border-radius: 12px;
  padding-bottom: 1rem;

  .traits-to-show-container {
    transform: translateY(-3px);
    z-index: 100;

    .traits-to-display-label {
      transform: translateY(-1px);
    }

    .traits-to-display-value {
      font-size: 14px;
      font-weight: 600;
    }

    .dropdown-toggle.btn {
      padding: 0;
    }
  }

  .heading {
    text-align: center;
  }

  .form-txtarea {
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .download-btn {
    // color: $primary;
    transform: translateY(-3px);
    margin-right: 0.8rem;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }

  .tweet-preview {
    border-radius: 12px;
    background-color: $dark-sb;
    color: $white;
    padding: 12px;

    .head-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        font-size: 1.15rem;
        // margin-right: 8px;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        width: fit-content;

        .name {
          font-weight: 700;
          color: $white;
          line-height: 1;
        }

        .username {
        }
      }
    }

    .body-container {
      color: $white;
      padding-top: 1rem;
      .col-twitter-blue {
        &:hover {
          text-decoration: underline;
        }
      }

      // nft display
      img {
        border-radius: 10px;
      }
    }
  }

  .character-count {
    .char-val {
      margin-left: 5px;
      margin-right: 3px;
    }
  }
}

.use-example-collection-btn {
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

.block-card {
  .heading {
    text-transform: uppercase;
    font-size: 2.1rem;
    font-weight: 900;
    line-height: 1.4;
    // background: -webkit-linear-gradient($primary, #0effa6);
    background: -webkit-linear-gradient(#fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include respond("phone-land") {
      font-size: 1.7rem;
    }
    @include respond("phone") {
    }
  }
  .beta-badge {
    margin-left: 10px;
    background-color: #b3b3b3;
    color: #000;
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 7px;
    padding-right: 7px;
    // padding-top: 2px;
    // padding-bottom: 2px;
    border-radius: 8px;
    text-align: center;
  }
}

.block-card.landmark {
  .card-header {
    padding: 10px;
    padding-left: 20px;
  }
  .heading {
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 900;
    line-height: 1.4;
    // background: -webkit-linear-gradient($primary, #0effa6);
    background: -webkit-linear-gradient(#fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include respond("phone-land") {
      font-size: 1.7rem;
    }
    @include respond("phone") {
    }
  }
  .beta-badge {
    margin-left: 10px;
    background-color: #b3b3b3;
    color: #000;
    font-size: 0.8rem;
    font-weight: 700;
    padding-left: 7px;
    padding-right: 7px;
    // padding-top: 2px;
    // padding-bottom: 2px;
    border-radius: 8px;
    text-align: center;

    // hide
    opacity: 0;
    display: none;
  }
  .block-card-img {
    width: 70px !important;
  }
}

.view-tweet-url-container {
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2rem;

  a {
    color: #1da1f2;
    &:hover {
      text-decoration: underline;
    }
  }
}

.flexbot {
  .p-float-label > label {
    color: unset;
  }

  .collectionMultiSelect {
    width: 100%;
    max-width: 350px;
    margin: auto;
  }
  .collectionSpanContainer {
  }
  .collectionImage {
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: -20px;
  }
  .collectionImage:first-child {
    margin-left: 0;
  }
  .collectionStacker {
    font-size: 1.25rem;
  }

  .multi-select {
  }
}

// primereact multiselect css
.flexbot-multi-select-col-info {
  .col-img {
  }
  .col-name {
    line-height: 1;
  }

  .col-no-owned {
    font-size: 0.8rem;
    line-height: 1;
  }
}

.flexbot-multi-select-footer {
  color: #959595;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #000000;
  background: #000000;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.4rem;
  border-radius: 16px;
  max-width: 71px;
  font-size: 1rem;
  background: $primary;
  color: #000000;
}
.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-multiselect {
  background: #060a05;
  border: 1px solid $primary;
}
.p-multiselect-panel {
  background: #161616;
  color: #ffffff;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #000000;
  background: #00e3b4;
  border-top: #161616 solid 2px;
  border-bottom: #161616 solid 2px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.25rem 1.25rem;
  color: #ffffff;
  border-top: #161616 solid 2px;
  border-bottom: #161616 solid 2px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #000000;
  background: #000000;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #ffffff;
  background: #1e1e1e;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid $primary;
  background: #000000;
  width: 17px;
  height: 17px;
  color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $primary;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem $primary;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #bbbbbb;
  background: #505050;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #bbbbbb;
  background: #505050;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: $primary;
}
.p-multiselect.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #00e3b4;
  color: #343a40;
  background: #000000;
}
.p-inputtext {
  color: rgb(233, 233, 233);
  background: #202124;
  padding: 0.4rem 0.4rem;
  border: 1px solid #00e3b4;
}
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.1rem #00e3b4;
  border-color: #00e3b4;
}
.p-inputtext:enabled:hover {
  border-color: #00e366;
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  color: #b1b1b1;
}
.outputHeader {
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  padding-bottom: 5px;
}
.snapshot-collection-name {
  font-size: 1rem;
}
.snapshot-collection-time {
  font-size: 1rem;
  color: #adadad;
}
.snapshot-output-textbox {
  width: 100%;
  height: 100%;
  border: 1px solid #00e3b4;
  background: #000000;
  color: rgb(233, 233, 233);
  padding: 10px;
  border-radius: 8px;
}
.outputBody {
  align-items: center;
}
.snapshot-csv-row {
  cursor: default !important;
  td {
    vertical-align: top !important;
    white-space: normal !important;
  }
}
.snapshot-pill {
  border: 1px solid #00e3b600;
  border-radius: 20px;
  transition: all 0.15s;
  margin-right: 2px;
  cursor: pointer;

  .nav-link {
    color: #fff;
    border-radius: 20px;

    &.active {
      color: #000;
    }
  }
  &:hover {
    border: 1px solid #00e3b4;
  }
}
.snapshot-output-container {
  margin-top: 12px;
}
.outputBody table {
  max-height: 2000px;
}
.outputTableRow {
  background-color: #202124;
  border: 1px solid #00e3b4;
}
.outputTableCell {
  border: 1px solid #00e3b4;
}
.outputTable {
  color: rgb(233, 233, 233);
}
.outputTableBody {
  color: rgb(233, 233, 233);
}
.outputSaveButton {
  width: 150px;
  text-align: center;
  border: 1px solid red;
  border-bottom: 0;
  float: right;
  display: flex;
  text-align: center;
}
.outputTableTab {
  display: flex;
}
.generateBtn {
  margin-top: 10px;
  display: grid;
}
.liveDateContainer button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.datePickerSnapshot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.liveDateActive {
  background-color: red;
  color: white;
}

.block-index-type-title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
}

[data-theme-version="dark"] .card {
  &.type-tool {
    // background-color: #05304e;
  }
}

// Rewards Bot
.rewardsBotSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userListAdder {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.userListAdder input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  width: 50%;
}
.userListAdder button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btnAdd {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btnUpload {
  color: white;
}

.userListContainer {
  display: flex;
  justify-content: center;
}
.userListControl {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 227, 180, 0.6);
}
.userControlBranding {
  position: absolute;
  width: 12%;
  opacity: 10%;
  right: 30px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}
.userControlHeaderBranding {
  opacity: 10%;
  width: 100%;
  overflow: hidden;
}
.userControlHeaderBrandingContainer {
  height: 100px;
  position: absolute;
}
.userListSymbol {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.userListNames {
  color: #000;
  background-color: #cf911d;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  display: flex;
  position: relative;
  cursor: pointer;

  .delete {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .handle {
    opacity: 1;
  }

  &:hover {
    background-color: #f55454 !important;

    .delete {
      opacity: 1;
    }
    .handle {
      opacity: 0;
    }
  }
}
.userListNamesVerified {
  background-color: #00e3b4;
}
.userListNamesError {
  background-color: #a82e2e;
}

.twitter-handles-container {
  display: inline-block;
  width: 100%;
  height: fit-content;
  /* max-height: 100px; */
  text-align: center;
  overflow-y: auto;
  padding-bottom: 15px;
}

.userListNameContainer {
  display: inline-block;
  margin-top: 8px;
  margin-right: 4px;
  margin-left: 4px;
}
.userListTotalUsers {
  width: 100%;
  text-align: center;
  align-items: center;
}
.userListTotalUsers button {
  margin-left: 20px;
}
.userListErrorMessage {
  color: red;
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 10px;
}
.userListNames.verified {
  background-color: #00e3b4;
}
.userListNames.error {
  background-color: #ec5252;
}
.rewardsBotWarningFooter {
  text-align: center;
  width: 100%;
  color: rgba(229, 227, 234, 0.363);
}

.userListDeleteUser {
  background-color: darkred !important;
  cursor: pointer;
}
