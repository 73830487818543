.breadcrumb {
  font-size: 1.125rem;
  .breadcrumb-item {
    a {
      svg {
        margin-top: 2px;
        margin-right: 5px;
        @include custommq($max: 100rem) {
          margin-top: 0;
        }
      }
    }
    &.active {
      a {
        color: $black;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: var(--primary);
  }
}

.page-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1.875rem;
  background: #ffffff;
  margin-bottom: 1.875rem;
  border-radius: 0.625rem;
  position: relative;
  z-index: 1;
  @at-root [data-theme-version="dark"] & {
    background: $dark-card;
  }

  @include respond("tab-land") {
    margin-top: 0;
  }
  @include respond("phone-land") {
    // margin-left: -1.25rem;
    // margin-right: -1.25rem;
    margin-bottom: 0.9375rem;
    padding: 0.9375rem 1.35rem;
  }

  .justify-content-sm-end {
    align-items: center;
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--primary);
    font-size: 1.25rem;
    span {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    // font-size: 0.875rem;
    li {
      margin-top: 0;
      margin-bottom: 0;

      a {
        color: $d-ctl;
        @include respond("phone") {
          font-size: 1.15rem;
        }
      }

      &.active {
        color: var(--primary);
        font-weight: 600;
        a {
          color: var(--primary);
        }
      }
    }

    .breadcrumb-item + .breadcrumb-item {
      &:before {
        //content: "\e606";
        /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
        color: var(--primary);
      }
    }

    &-datepicker {
      font-size: 0.75rem;
      color: $muted;

      &__icon {
        font-size: 0.875rem;
      }
    }
  }

  .breadcrumb-widget {
    .border-dark {
      border-color: $gray-300 !important;
    }
    h4 {
      color: $strong;
      font-weight: 600;
    }
    @include respond("phone") {
      text-align: left !important;
      margin-bottom: 0.9375rem;
    }
  }
}
