.marketplace-wrapper {
  a {
    color: #0072ff;
  }
  .marketplace-container {
    width: 100%;
    .marketplace-form-container {
      max-width: 400px;
      margin: auto;
      padding-top: 20px;
      padding-bottom: 40px;
    }
    // display: flex;
    // justify-content: center;
    // margin: auto;
  }

  .btn.btn-primary {
    &.disabled {
      opacity: 0.2;
      cursor: default;
    }
  }

  .buttons-row {
    display: flex;
    padding-left: 22px;
    padding-right: 22px;

    .btn {
      margin-right: 12px;
      margin-top: 20px;
      margin-bottom: 20px;

      &.del {
        background-color: #a52c2c !important;

        &:hover {
          background-color: #7d2222 !important;
        }
      }
    }
  }

  .item-img {
    width: 70px;
  }

  .email.sm {
    font-size: 10px;
  }

  .img-container {
    display: flex;
    align-items: center;
  }

  .clipboard-icon.image {
    // width: 100%;
    // display: flex;
    // justify-content: center;
    margin: auto;
    margin-left: 6px;
  }

  .dev-checklist {
    width: 100%;
    max-width: 400px;
    margin: auto;

    .title {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &.bets-dashboard-wrapper {
    .example {
      font-size: 12px;
      font-style: italic;
    }

    .tr-description {
      max-width: 240px;
    }

    .wrap-text {
      word-wrap: break-word; /* Ensures long words break and wrap onto the next line */
      word-break: break-word; /* Ensures long words break at the end of the line */
      white-space: normal; /* Ensures text wraps normally and respects the element width */
    }

    .delete-text-btn {
      color: #a81a1a;

      &:hover {
        color: #c31616;
      }
    }
  }
}
