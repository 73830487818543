// modal code has to be outside the wrapper

.rsvp-modal {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.rsvp-modal .form-control {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rsvp-modal .form-control:focus {
  border-color: #61999d;
  outline: 0;
  box-shadow: none;
}
.rsvp-modal .check__box span {
  border: 2px solid #61999d;
}
.rsvp-modal .check__box input:checked ~ span {
  background-color: #61999d;
}
.rsvp-modal .legal-notice {
  text-align: center;
  color: #000;
  font-size: 10px !important;
  max-width: 360px;
  margin: auto;
  margin-bottom: 0 !important;
}
.rsvp-modal .legal-notice a {
  font-size: 10px !important;
}
.rsvp-modal .modal-content {
  padding: 50px;
  padding-left: 38px;
  padding-right: 38px;
  border: #dcd9d0 2px solid;
  border-radius: 20px;
  z-index: 10000;
  border-width: 1px;
  border-color: #dcd9d0;
}
.rsvp-modal .rsvp-modal-title {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.rsvp-modal .rsvp-modal-title.sm {
  font-size: 2rem;
}
.rsvp-modal .rsvp-submitted-text {
  font-size: 1.1rem;
}
.rsvp-modal .rsvp-modal-close {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: unset;
  top: 10px;
  right: 14px;
  margin: auto;
  transition: color 0.2s;
  font-size: 22px;
  cursor: pointer;
}
.rsvp-modal .rsvp-modal-close:hover {
  color: #ba1414;
}
.rsvp-modal .register-button {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  background-color: #61999d;
  background-color: var(--theme-primary-color);
  color: #fff;
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.15s;
  font-weight: 400;
  font-size: 22px;
  border-radius: 8px;
  width: 100%;
  letter-spacing: 1.3px;
}
.rsvp-modal .register-button:hover {
  // background-color: #487073;
}
.rsvp-modal .fields-column {
  display: flex;
  flex-direction: column;
}
.rsvp-modal .fields-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}
.rsvp-modal .rsvp-field:not(.first) {
  margin-top: 16px;
}
.rsvp-modal .rsvp-field.last {
  margin-bottom: 16px;
}
.rsvp-modal .rsvp-field .rsvp-field-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.rsvp-modal .rsvp-field .rsvp-field-label .asterisk {
  margin-left: 2px;
  font-size: 18px;
  line-height: 1;
}
.rsvp-modal .rsvp-checkbox-container {
  margin-top: 16px;
}
.rsvp-modal .check-label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 1.5;
}
.rsvp-modal .check-label .inline-link {
  display: inline-block;
  font-size: 12px;
  color: #015637;
  line-height: 1.5;
}
.rsvp-modal .required-field-msg {
  color: #ba1414;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
  height: 30px;
}
.rsvp-modal .required-field-msg.bottom {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}
.rsvp-modal .rsvp-checkbox-container {
  margin-top: 16px;
}
.rsvp-modal .check {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 24px;
  line-height: 100%;
}
.rsvp-modal .check__box {
  border-radius: 2px;
  height: 22px;
  margin: 0 14px 9px 0;
  min-height: 22px;
  min-width: 22px;
  position: relative;
  width: 22px;
}
.rsvp-modal .check__box input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.rsvp-modal .check-label {
  color: #000;
  font-size: 16px;
  margin-top: -4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 8px;
}
.rsvp-modal .check__box span {
  border: 2px solid #61999d;
}
.rsvp-modal .check__box span {
  align-items: center;
  border: 2px solid #a7e2ee;
  border-radius: 2px;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.3s ease;
  width: 100%;
  z-index: 1;
}
.rsvp-modal .check__box span img {
  opacity: 0;
  transition: 0.3s ease;
  width: 12px;
}
.events-client-wrapper {
  html,
  body {
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  button {
    outline: none;
  }

  button:focus {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  /*** 
====================================================================
Global Settings
====================================================================
   ***/
  body {
    font-family: "FoundersGrotesk";
    font-size: 16px;
    color: #002b49;
    line-height: 20px;
    font-weight: 400;
    background: #fff;
  }
  body.active {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    opacity: 1;
    color: #fff;
    transition: 0.3s ease;
    cursor: pointer;
  }
  a:hover {
    opacity: 0.88;
  }

  ul li {
    list-style: none;
  }

  .auto__container {
    position: relative;
    max-width: 1370px;
    margin: 0 auto;
    padding: 0 40px;
  }
  .auto__container.nav-side-container {
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn {
    border: 2px solid transparent;
    border-radius: 0.625rem;
    padding: 0.5rem 1.4375rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.25rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
    line-height: normal;
  }

  .btn-primary {
    background: #a7e2ee;
    border-color: #a7e2ee;
    color: #002b49;
  }

  .btn-primary:hover {
    background: transparent;
    border-color: #00a6cb;
    color: #00a6cb;
  }

  .btn-check.nice-select.open + .btn-primary,
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary.nice-select.open,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #00223a;
    border-color: #002037;
    color: #fff;
  }

  h1 {
    font-weight: 600;
    font-size: 74px;
    line-height: 74px;
  }

  h2 {
    font-size: 66px;
    line-height: 71px;
    font-weight: 600;
  }

  h3 {
    font-size: 42px;
    line-height: 50px;
    font-weight: 500;
  }

  h4 {
    font-size: 26px;
    line-height: 31px;
    font-weight: 500;
  }
  h4.big {
    font-size: 30px;
    line-height: 100%;
  }

  h5 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }

  h6 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-transform: uppercase;
  }

  p.big {
    font-size: 18px;
    line-height: 27px;
  }

  p.sm {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }

  .main {
    overflow: visible;
    padding-top: 77px;
  }
  .main.removeP {
    padding: 0;
  }

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    font-family: "FoundersGrotesk";
    color: #002b49;
    padding: 12px 26px 6px;
    background-color: #a7e2ee;
    cursor: pointer;
    transition: 0.3s ease;
    border: 2px solid transparent;
  }
  .button:not(.disabled):hover {
    border-color: #002b49;
    background-color: #002b49;
    color: #fff;
  }

  .check {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 24px;
    line-height: 100%;
  }
  .check__box {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border-radius: 2px;
    position: relative;
    margin: 0 14px 9px 0;
  }
  .check__box input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
  .check__box input:checked ~ span {
    background-color: #a7e2ee;
  }
  .check__box input:checked ~ span img {
    opacity: 1;
  }
  .check__box span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 2px;
    transition: 0.3s ease;
    border: 2px solid #a7e2ee;
  }
  .check__box span img {
    width: 12px;
    opacity: 0;
    transition: 0.3s ease;
  }
  .check b {
    width: calc(100% - 36px);
    font-weight: 400;
  }

  .input {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input__outer h5 {
    font-size: 17px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 6px;
  }
  .input select {
    word-wrap: normal;
    height: 100%;
    width: 100%;
    background-color: #f4f7fb;
    padding: 17px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: "FoundersGrotesk";
  }
  .input input {
    width: 100%;
    background-color: #f4f7fb;
    padding: 17px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: "FoundersGrotesk";
  }
  .input textarea {
    width: 100%;
    background-color: #f4f7fb;
    padding: 17px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: "FoundersGrotesk";
    resize: unset;
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes resize {
    0% {
      transform: matrix(1, 0, 0, 1, 289.54501, 167.409);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 300.409);
    }
  }

  .modall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #00000048;
    z-index: 12;
    display: flex;
  }
  .modallClose {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 3px 6px #00000036;
    z-index: 15;
    background-color: #00a6cb;
  }
  .modallClose:hover {
    background-color: #33daff;
  }
  .modallClose::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 3px;
    background-color: #002b49;
    transform: translate(-50%, -50%) rotate(45deg);
    content: "";
    transition: 0.3s ease;
  }
  .modallClose::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 3px;
    background-color: #002b49;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: "";
    transition: 0.3s ease;
  }
  .modall__inner {
    margin: auto;
    background-color: #002b49;
    color: #fff;
    padding: 78px 20px 30px;
    font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 540px;
    width: 100%;
  }
  .modall__inner .input__outer {
    margin-bottom: 20px;
  }
  .modall__inner .input__outer:last-child {
    margin: 0;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .btn-std {
    width: fit-content;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .btn-std:hover {
    background: transparent;
    border-color: #00a6cb;
    color: #00a6cb;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #002b49;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .header.sticky {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.8);
  }
  .header .header__inner {
    padding: 20px 0;
  }
  .header__inner {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header__inner-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 207px;
  }
  .header__inner-logo img {
    width: 100%;
  }
  .header__inner-side {
    display: flex;
    align-items: center;
  }
  .header__inner-links {
    transform: translateY(4px);
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .header__inner-links li {
    margin-right: 28px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header__inner-links li:last-child {
    margin: 0;
  }
  .header__inner-links li > a:hover {
    opacity: 1;
    color: #00a6cb;
  }
  .header__inner-links li a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 100%;
    font-weight: 600;
    color: #fff;
  }
  .header__inner-links .dropdown {
    position: relative;
  }
  .header__inner-links .dropdown:hover .dropdown__menu {
    visibility: visible;
    opacity: 1;
  }
  .header__inner-links .dropdown:hover > a {
    color: #00a6cb;
  }
  .header__inner-links .dropdown:hover > a span::after {
    opacity: 0;
  }
  .header__inner-links .dropdown > a {
    display: flex;
    align-items: flex-start;
  }
  .header__inner-links .dropdown > a span {
    width: 12px;
    height: 12px;
    position: relative;
    top: 50%;
    right: 0;
    margin: 0 0 0 5px;
  }
  .header__inner-links .dropdown > a span::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #00a6cb;
    content: "";
    transform: translateY(-50%);
  }
  .header__inner-links .dropdown > a span::after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #00a6cb;
    content: "";
    transform: translateX(-50%);
    transition: 0.3s ease;
  }
  .header__inner-links .dropdown__menu {
    padding: 17px 7px;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 310px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    visibility: hidden;
    transition: 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
  }
  .header__inner-links .dropdown__menu a {
    width: 100%;
    color: #002b49;
    border-radius: 4px;
    padding: 18px 36px 10px;
    text-transform: capitalize;
    font-weight: 400;
  }
  .header__inner-links .dropdown__menu a:first-child {
    padding: 18px 18px 10px;
  }
  .header__inner-links .dropdown__menu a:hover {
    opacity: 1;
    background-color: #f8f3ec;
  }

  @keyframes sticky {
    0% {
      top: -60px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: #002b49;
    transform: translateX(100%);
    padding: 65px 0 180px;
    transition: 0.6s ease;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .nav::-webkit-scrollbar-thumb {
    background: #00a6cb;
    border-radius: 10px;
  }
  .nav.active {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .nav__lines {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .nav__lines img {
    width: 100%;
  }
  .nav__close {
    z-index: 5;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a7e2ee;
    position: relative;
    margin: 0 0 0 auto;
    cursor: pointer;
    transition: 0.3s ease;
    margin-bottom: 25px;
  }
  .nav__close:hover {
    background-color: #00a6cb;
  }
  .nav__close::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 3px;
    background-color: #002b49;
    transform: translate(-50%, -50%) rotate(45deg);
    content: "";
    transition: 0.3s ease;
  }
  .nav__close::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 3px;
    background-color: #002b49;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: "";
    transition: 0.3s ease;
  }
  .nav__inner {
    flex-direction: column;
    color: #fff;
  }
  .nav__inner-row {
    display: flex;
    justify-content: space-evenly;
  }
  .nav__inner-links {
    width: calc(33.3% - 15px);
    max-width: 260px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav__inner-links a {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 30px;
    color: #a7e2ee;
    position: relative;
  }
  .nav__inner-links a:last-child {
    margin: 0;
  }
  .nav__inner-links a:hover {
    opacity: 1;
    color: #fff;
  }
  .nav__inner-links a:hover::before {
    width: 100%;
  }
  .nav__inner-links a::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    width: 0;
    height: 4px;
    background-color: #00a6cb;
    transition: 0.4s ease;
  }
  .nav__inner-social {
    width: calc(33.3% - 15px);
    max-width: 180px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }
  .nav__inner-social h4 {
    margin-bottom: 5px;
    color: #a7e2ee;
  }
  .nav__inner-social a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #ddc9a3;
    font-size: 22px;
    line-height: 100%;
  }
  .nav__inner-social a:hover {
    opacity: 1;
    color: #fff;
  }
  .nav__inner-social a span {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 7px 0;
  }
  .nav__inner-social a span svg {
    width: 100%;
    height: 100%;
    color: #a7e2ee;
    min-width: 22px;
  }
  .nav__inner-col {
    max-width: 260px;
    width: calc(33.3% - 15px);
  }
  .nav__inner-col h4 {
    font-weight: 400;
    color: #a7e2ee;
    margin-bottom: 5px;
  }
  .nav__inner-col p {
    color: #ddc9a3;
    margin-bottom: 15px;
  }
  .nav__inner-col .button.big {
    margin-bottom: 40px;
    padding: 13px 34px 7px;
    border-radius: 15px;
    background-color: #00a6cb;
    font-size: 24px;
    line-height: 100%;
    color: #fff;
    font-weight: 400;
    border: 2px solid #00a6cb;
  }
  .nav__inner-col .button.big:hover {
    background-color: transparent;
  }
  .nav__inner-col .button.sm {
    font-size: 14px;
    border-radius: 10px;
    padding: 9px 16px 5px;
    line-height: 100%;
  }
  .nav__inner-col .input {
    margin-bottom: 20px;
  }
  .nav__inner-col .input input {
    padding: 8px 18px 3px;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #a7e2ee;
    color: #fff;
    font-size: 14px;
    line-height: 100%;
  }
  .nav__inner-col .input input::placeholder {
    color: #a7e2ee;
  }

  .burger {
    display: block;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    position: relative;
    width: 26px;
    height: 20px;
    margin-left: 28px;
  }
  .burger:hover {
    opacity: 0.8;
  }
  .burger::before {
    top: 0%;
    width: 100%;
    height: 3px;
    background-color: #a7e2ee;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .burger span {
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: #a7e2ee;
    transition: all 0.3s ease;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-ransform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
  }
  .burger::after {
    bottom: 0%;
    width: 50%;
    height: 3px;
    background-color: #a7e2ee;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 0;
  }

  .footer {
    padding: 70px 0 35px;
  }
  .footer .auto__container {
    max-width: 1200px;
  }
  .footer__inner.alt {
    transform: translateY(-37px);
  }
  .footer__inner-row {
    display: flex;
    align-items: flex-start;
    padding: 40px 0 50px;
  }
  .footer__inner-top {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__inner-top:not(.alt)::before {
    position: absolute;
    content: "";
    top: 30px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #a7a7b3;
  }
  .footer__inner-logo {
    width: 120px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 0 auto 12px;
    position: relative;
    z-index: 2;
  }
  .footer__inner-logo img {
    width: 50%;
  }
  .footer__inner-logo.alt {
    background-color: #00000000;
    margin-bottom: 20px;
  }
  .footer__inner-col {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__inner-col.social a {
    margin-bottom: 10px;
  }
  .footer__inner-col a {
    font-size: 16px;
    line-height: 100%;
    color: #002b49;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
  .footer__inner-col a:hover {
    opacity: 1;
    color: #00a6cb;
  }
  .footer__inner-col a:hover svg {
    color: #00a6cb;
  }
  .footer__inner-col a:last-child {
    margin: 0;
  }
  .footer__inner-col a:nth-child(n + 6) {
    display: none;
  }
  .footer__inner-col a span {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 5px;
  }
  .footer__inner-col a span svg {
    width: 100%;
    height: 100%;
    color: #002b49;
    transition: 0.3s ease;
  }
  .footer__inner-main {
    width: calc(40% - 16px);
    margin-right: 16px;
  }
  .footer__inner-main h6 {
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 4px;
  }
  .footer__inner-main p {
    margin-bottom: 20px;
    max-width: 280px;
  }
  .footer__inner-main .input {
    margin-bottom: 20px;
    max-width: 280px;
  }
  .footer__inner-main .input input {
    padding: 8px 18px 3px;
    background-color: transparent;
    border-radius: 15px;
    font-size: 14px;
    line-height: 100%;
    border: 1px solid #a7a7b3;
    color: #002b49;
  }
  .footer__inner-main .input input::placeholder {
    color: #a7a7b3;
  }
  .footer__inner-main .button {
    font-size: 14px;
    border-radius: 10px;
    padding: 9px 16px 5px;
    line-height: 100%;
  }
  .footer__inner-copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer__inner-copy-links {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .footer__inner-copy-links a {
    font-size: 12px;
    line-height: 100%;
    color: #00a6cb;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .footer__inner-copy-links hr {
    width: 1px;
    height: 16px;
    background-color: #00a6cb;
    margin: 0 6px 5px;
  }
  .footer__inner h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-transform: capitalize;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hero {
    position: relative;
  }
  .hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero__bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: 70%;
  }
  .hero__inner {
    min-height: calc(97vh - 129px);
    color: #fff;
    display: flex;
    align-items: center;
  }
  .hero__inner-content {
    max-width: 760px;
  }
  .hero__inner h1 {
    margin-bottom: 40px;
  }
  .hero__inner p {
    margin-bottom: 45px;
    font-size: 18px;
  }

  .join {
    background-color: #edf9fc;
    padding: 120px 0;
  }
  .join__inner h3 {
    text-align: center;
    margin-bottom: 28px;
  }
  .join__inner-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -17px;
  }
  .joinItem {
    margin: 17px 17px 27px;
    width: calc(25% - 34px);
    background-color: #fff;
    padding: 15px 15px 24px;
    box-shadow: 10px 10px 0 #a7e2ee;
    display: block;
    cursor: pointer;
    color: #002b49;
  }
  .joinItem:hover {
    opacity: 1;
  }
  .joinItem:hover img {
    transform: scale(1.1);
  }
  .joinItem__image {
    position: relative;
    padding-bottom: 80%;
    overflow: hidden;
  }
  .joinItem__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    transition: 0.3s ease;
  }
  .joinItem__content {
    padding: 30px 0 0 15px;
  }
  .joinItem h6 {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: #fff;
    padding: 8px 8px 2px;
  }
  .joinItem h4 {
    margin-bottom: 11px;
  }
  .joinItem p {
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  .company {
    background-color: #fff;
    padding: 70px 0;
  }
  .company__inner-slider {
    margin-bottom: 50px;
  }
  .company__inner h3 {
    text-align: center;
    margin-bottom: 60px;
  }
  .company__inner .slick-track {
    display: flex !important;
    align-items: center;
  }
  .company__inner .slick-slide {
    height: unset !important;
  }
  .companyItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
  }
  .companyItem img {
    width: 100%;
    max-height: 32px;
    height: 32px;
    width: auto;
  }

  .request {
    padding: 62px 0;
    background-color: #f8f3ec;
  }
  .request__inner-circle {
    position: absolute;
    top: 22%;
    right: 0;
    width: 44%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(30%);
  }
  .request__inner-circle img {
    width: 100%;
    animation: loading 18s linear infinite;
  }
  .requestForm {
    max-width: 947px;
    margin: 0 auto;
    background-color: #fff;
    padding: 78px 64px 60px;
    position: relative;
  }
  .requestForm__row {
    display: flex;
    flex-wrap: wrap;
  }
  .requestForm__row:not(.unified) {
    margin: 0 -11px 20px;
  }
  .requestForm__inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .requestForm h3 {
    margin-bottom: 9px;
  }
  .requestForm h3 b {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
  .requestForm > p {
    margin-bottom: 6px;
    max-width: 730px;
  }
  .requestForm .input__outer {
    width: 100%;
    margin-bottom: 30px;
  }
  .requestForm .input__outer.sm {
    width: calc(50% - 15px);
  }
  .requestForm .button {
    width: 100%;
    max-width: 140px;
  }
  .requestFormItem {
    margin: 11px;
    width: calc(50% - 22px);
  }
  .requestFormItem__inner {
    padding-bottom: 24%;
    position: relative;
  }
  .requestFormItem__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 19px 12px;
    background-color: #e2faff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    transition: 0.3s ease;
  }
  .requestFormItem input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  .requestFormItem input:hover ~ .requestFormItem__content {
    background-color: #a7e2ee;
  }
  .requestFormItem input:checked ~ .requestFormItem__content {
    background-color: #002b49;
    color: #fff;
  }
  .requestFormItem h5 {
    margin-bottom: 3px;
  }

  .events {
    background-color: #002b49;
    padding: 70px 0;
  }
  .events .auto__container {
    max-width: 1490px;
    z-index: 2;
  }
  .events__inner .slick-track {
    display: flex !important;
    align-items: stretch;
  }
  .events__inner .slick-slide {
    height: unset !important;
  }
  .events__inner .slick-list {
    overflow: visible !important;
  }
  .eventsItem {
    padding: 0 5px;
  }
  .eventsItem__inner {
    padding-bottom: 85%;
    position: relative;
  }
  .eventsItem__inner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
  }

  .partner {
    background-color: #002b49;
    padding: 80px 0 85px;
    color: #fff;
  }
  .partner__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .partner__inner-form {
    width: calc(55% - 15px);
    max-width: 500px;
  }
  .partner__inner-title {
    width: calc(45% - 15px);
  }
  .partner__inner h2 {
    padding-bottom: 24px;
    position: relative;
  }
  .partner__inner h2::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 3px;
    background-color: #a7e2ee;
    content: "";
  }
  .partner__inner .check {
    margin-bottom: 17px;
  }
  .partner__inner .check:last-of-type {
    margin: 0;
  }
  .partner__inner .button {
    width: 100%;
    max-width: 140px;
    margin-top: 30px;
  }

  .check-label {
    font-size: 16px;
    color: #000;
    margin-top: -4px;
  }

  .hero-title {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.56);
  }

  .hero-sub-text {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
    font-size: 20px;
  }

  .event-img {
    width: 88px;
  }

  .event-info {
    text-align: left;
    margin-left: 14px;
  }

  .event-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .d-flex {
    display: flex;
  }

  .h2-h1-text-white-mb-0-aos-init-aos-animate {
    box-sizing: border-box;
    margin-top: 0;
    font-size: 40px;
    line-height: 2.5rem;
    font-weight: 500;
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ease;
    opacity: 1;
    transform: translateZ(0);
    transition-delay: 0.8s;
    margin-bottom: 0;
    color: #fff;
  }
  @media (min-width: 1200px) {
    .h2-h1-text-white-mb-0-aos-init-aos-animate {
      font-size: 40px;
      line-height: 4rem;
    }
  }
  .h2-h1-text-white-mb-0-aos-init-aos-animate:after {
    box-sizing: border-box;
  }
  .h2-h1-text-white-mb-0-aos-init-aos-animate:before {
    box-sizing: border-box;
  }

  .inherited-styles-for-exported-element {
    font-family: "FoundersGrotesk", system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    text-align: start;
  }

  .span-eyebrow-eyebrow-1-text-secondary-d-block-mb-1-mb-xl-2-aos-init-aos-animate {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ease;
    opacity: 1;
    transform: translateZ(0);
    transition-delay: 0.4s;
    display: block;
    margin-bottom: 0.3125rem;
    color: #00a6cb;
  }
  @media (min-width: 768px) {
    .span-eyebrow-eyebrow-1-text-secondary-d-block-mb-1-mb-xl-2-aos-init-aos-animate {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) {
    .span-eyebrow-eyebrow-1-text-secondary-d-block-mb-1-mb-xl-2-aos-init-aos-animate {
      font-size: 16px;
      margin-bottom: 0.625rem;
    }
  }
  .span-eyebrow-eyebrow-1-text-secondary-d-block-mb-1-mb-xl-2-aos-init-aos-animate:after {
    box-sizing: border-box;
  }
  .span-eyebrow-eyebrow-1-text-secondary-d-block-mb-1-mb-xl-2-aos-init-aos-animate:before {
    box-sizing: border-box;
  }

  .inherited-styles-for-exported-element {
    text-align: start;
    font-family: "FoundersGrotesk", system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  .span-text-white {
    box-sizing: border-box;
    color: #fff;
  }
  .span-text-white:after {
    box-sizing: border-box;
  }
  .span-text-white:before {
    box-sizing: border-box;
  }

  .span-leadspace-home__spanner {
    box-sizing: border-box;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: #00a6cb;
    display: inline-block;
  }
  .span-leadspace-home__spanner:after {
    box-sizing: border-box;
  }
  .span-leadspace-home__spanner:before {
    box-sizing: border-box;
  }

  .inherited-styles-for-exported-element {
    font-family: "FoundersGrotesk", system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    text-align: start;
    font-weight: 500;
    font-size: 40px;
    line-height: 2.5rem;
  }

  @media (min-width: 1200px) {
    .inherited-styles-for-exported-element {
      font-size: 40px;
      line-height: 4rem;
    }
  }

  .css-scan-generated-variation-p-1 {
    box-sizing: border-box;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1.5625rem;
    margin-left: 0;
  }
  @media (min-width: 768px) {
    .css-scan-generated-variation-p-1 {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 1.5625rem;
      margin-left: 0;
    }
  }
  .css-scan-generated-variation-p-1:after {
    box-sizing: border-box;
  }
  .css-scan-generated-variation-p-1:before {
    box-sizing: border-box;
  }
  .css-scan-generated-variation-p-1:last-child {
    margin-bottom: 0;
  }

  .inherited-styles-for-exported-element {
    font-family: "FoundersGrotesk", system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    text-align: start;
    font-size: 22px;
    line-height: 1.4375rem;
    font-weight: 400;
    color: #f1eff4;
  }

  @media (min-width: 1200px) {
    .inherited-styles-for-exported-element {
      font-size: 22px;
      line-height: 1.75rem;
    }
  }

  a {
    text-decoration: none !important;
    color: #002b49;
    display: block;
    font-size: 1rem;
    letter-spacing: 0.03125rem;
    line-height: 2.125rem;
  }

  .a-btn-btn-primary-link-contact {
    transition: all 0.3s;
    text-decoration: none;
    border-radius: 0.625rem;
    box-sizing: border-box;
    vertical-align: middle;
    user-select: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.25rem;
    font-size: 20px;
    padding: 0.5rem 1.4375rem;
    border-style: solid;
    border-width: 2px;
    color: #002b49;
    border-color: #a7e2ee;
    background-color: #a7e2ee;
  }
  @media (min-width: 1200px) {
    .a-btn-btn-primary-link-contact {
      line-height: 1.25rem;
      font-size: 20px;
    }
  }
  .a-btn-btn-primary-link-contact:after {
    box-sizing: border-box;
  }
  .a-btn-btn-primary-link-contact:before {
    box-sizing: border-box;
  }
  .a-btn-btn-primary-link-contact:hover {
    color: #00a6cb;
    border-color: #00a6cb;
    background-color: transparent;
  }
  .a-btn-btn-primary-link-contact:focus {
    outline: 0;
    color: #fff;
    border-color: #00223a;
    background-color: #00253e;
    box-shadow: none;
  }
  .a-btn-btn-primary-link-contact:disabled {
    pointer-events: none;
    opacity: 0.65;
    color: #fff;
    border-color: #002b49;
    background-color: #002b49;
  }
  .a-btn-btn-primary-link-contact:active {
    outline: 0;
    color: #fff;
    border-color: #002037;
    background-color: #00223a;
    box-shadow: none;
  }
  .a-btn-btn-primary-link-contact:active:focus {
    box-shadow: rgba(38, 75, 100, 0.5) 0 0 0 0.25rem;
  }

  .inherited-styles-for-exported-element {
    font-family: "FoundersGrotesk", system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  .z1 {
    z-index: 1;
  }

  .z2 {
    z-index: 2;
  }

  .hero-circle {
    position: absolute;
    top: 15%;
    right: 2%;
    width: 44%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: translateX(30%); */
    z-index: 1;
    opacity: 1;
  }

  .hero-circle img {
    width: 100%;
    animation: loading 18s linear infinite;
  }

  .events {
    padding: 55px 0;
    padding-top: 10px !important;
  }

  .footer {
    padding-top: 5px !important;
  }

  .form-invalid-msg {
    font-size: 16px;
    color: #b33232;
    margin-top: 6px;
  }

  .submit-btn.disabled {
    opacity: 0.3;
    cursor: default !important;
  }

  .submit-btn.disabled:hover {
    opacity: 0.3;
  }

  .companyItem {
    width: fit-content;
    height: 32px;
    object-fit: contain;
  }

  .companyItem img {
    width: auto;
    height: 32px;
    max-width: 100%;
    object-fit: contain;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  /* Apply custom styles to the file input button */
  .custom-file-input {
    display: none;
    /* Hide the default input button */
  }

  /* Apply custom styles to the custom button */
  .custom-file-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  .custom-file-button .btn-text {
    transform: translateY(2px);
  }

  .custom-file-button:hover {
    background-color: #2980b9;
  }

  /* Apply styles to the selected filename */
  .selected-filename {
    margin-left: 10px;
    font-size: 14px;
    color: #333;
  }

  .req-item.extended {
    position: relative;
    width: 100%;
    height: fit-content;
    margin-bottom: 8px;
  }
  .req-item.extended.event {
    padding-right: 8px;
  }
  .req-item.extended.event .req-item-upper .req-item-img {
    width: 20%;
    padding-right: 8px;
    margin-right: 8px;
  }
  .req-item.extended .req-item-content {
    padding: 15px 19px 12px;
    background-color: #fff;
    transition: 0.3s ease;
  }
  .req-item.extended input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  .req-item.extended input[type="checkbox"]:hover ~ .req-item-content {
    background-color: #d2f7ff;
  }
  .req-item.extended input[type="checkbox"]:checked ~ .req-item-content {
    background-color: #a7e2ee;
  }
  .req-item.extended .req-item-upper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .req-item.extended .req-item-upper .req-item-img {
    width: 17%;
    padding-right: 24px;
  }
  @media (max-width: 750px) {
    .req-item.extended .req-item-upper .req-item-img {
      width: 23%;
      padding-right: 11px;
    }
  }
  .req-item.extended .req-item-upper .req-item-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .req-item.extended .req-item-upper .req-item-header h5 {
    margin-bottom: 0;
  }
  .req-item.extended .req-item-upper .req-item-header .sm.short-desc {
    margin-bottom: 1px;
    font-weight: 500;
  }
  .req-item.extended .req-item-upper .req-item-header .sm.long-desc {
    color: #2b2b2b;
  }
  .req-item.extended .req-item-lower {
    width: 100%;
  }

  .show-mob {
    display: none;
  }

  .hide-mob {
    display: inline-block;
  }

  .league-spartan-font {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  .league-spartan-font.bold {
    font-weight: 700;
  }
  .league-spartan-font.extra-bold {
    font-weight: 900;
  }

  .loading-page {
    position: fixed;
    /* Ensure it covers the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: center;
    /* Vertically center the content */
    background-color: #f2f2f2;
    z-index: 1000;
    /* Ensure it stays on top of other elements */
  }

  .loading-page img {
    width: 100px;
    /* Adjust the size of the spinner as needed */
    height: 100px;
    /* Ensure aspect ratio is maintained */
  }

  @media (max-width: 1180px) {
    .auto__container {
      padding: 0 30px;
    }
    .main {
      padding-top: 77px;
    }
    h1 {
      font-size: 60px;
      line-height: 70px;
    }
    h2 {
      font-size: 55px;
      line-height: 65px;
    }
    h3 {
      font-size: 35px;
      line-height: 40px;
    }
    .hero__inner h1 {
      margin-bottom: 30px;
    }
    .hero__inner p {
      margin-bottom: 30px;
    }
    .header .header__inner {
      padding: 20px 0;
    }
    .header__inner {
      padding: 20px 0;
    }
    .header__inner-links {
      display: none;
    }
    .header__inner-logo {
      width: 180px;
    }
    .header__inner .burger {
      margin: 0;
    }
    .join {
      padding: 90px 0;
    }
    .join__inner h3 {
      margin-bottom: 18px;
    }
    .joinItem {
      width: calc(33.3% - 34px);
    }
    .partner {
      padding: 60px 0;
    }
    .partner__inner h2 {
      padding-bottom: 10px;
    }
    .events {
      padding: 55px 0;
    }
    .request__inner-circle {
      width: 250px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -45%);
    }
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      display: none;
    }
    h4 {
      font-size: 22px;
      line-height: 26px;
    }
    h4.big {
      font-size: 24px;
      line-height: 28px;
    }
    .partner {
      padding: 40px 0;
    }
    .partner__inner {
      flex-direction: column;
    }
    .partner__inner-title {
      width: 100%;
    }
    .partner__inner-form {
      width: 100%;
    }
    .partner__inner h2 {
      margin-bottom: 45px;
    }
    .nav {
      padding: 30px 0 160px;
    }
    .nav__lines {
      width: 400px;
      height: 400px;
    }
    .nav__close {
      width: 46px;
      height: 46px;
      margin-bottom: 20px;
    }
    .nav__inner-links a {
      font-size: 24px;
      margin-bottom: 22px;
    }
    .nav__inner .button.big {
      margin-bottom: 28px;
    }
    .footer {
      padding: 45px 0 24px;
    }
    .footer__inner-top::before {
      top: 23px;
    }
    .footer__inner-logo {
      width: 92px;
      height: 46px;
    }
    .footer__inner-row {
      padding: 35px 0;
    }
  }

  @media (max-width: 930px) {
    .industrial-tech .hero-circle {
      opacity: 0.66;
      right: -22%;
    }
    .join {
      padding: 70px 0;
    }
    .join__inner-row {
      margin: 0 -10px;
    }
    .joinItem {
      margin: 10px 10px 20px;
      width: calc(33.3% - 20px);
      box-shadow: 7px 7px 0 #a7e2ee;
      padding: 10px 10px 18px;
    }
    .joinItem__content {
      padding-top: 24px;
    }
    .joinItem h4 {
      margin-bottom: 6px;
    }
    .events {
      padding: 44px 0;
    }
    .request__inner-circle {
      width: 200px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -40%);
    }
    .requestForm {
      padding: 50px 40px;
    }
    .requestForm h3 {
      margin-bottom: 30px;
    }
    .requestForm .input__outer {
      margin-bottom: 20px;
    }
    .requestForm .input__outer.sm {
      width: calc(50% - 8px);
    }
    .requestForm .input h5 {
      margin-bottom: 3px;
    }
    .requestForm .input input {
      padding: 14px 12px 10px;
    }
  }

  @media (max-width: 750px) {
    label:not(:first-child) {
      margin-top: 16px;
    }
    .auto__container {
      padding: 0 20px;
    }
    h1 {
      font-size: 50px;
      line-height: 60px;
    }
    h2 {
      font-size: 48px;
      line-height: 55px;
    }
    h3 {
      font-size: 30px;
      line-height: 35px;
    }
    .nav {
      padding: 20px 0 140px;
    }
    .nav__lines {
      width: 300px;
      height: 300px;
      bottom: unset;
      opacity: 0.2;
    }
    .nav__inner-row {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 25px;
    }
    .nav__inner-links {
      width: calc(55% - 10px);
    }
    .nav__inner-social {
      width: calc(45% - 10px);
      max-width: 240px;
    }
    .nav__inner-col {
      width: 100%;
      max-width: 330px;
      margin-top: 40px;
    }
    .nav__inner .button.big {
      font-size: 22px;
      border-radius: 10px;
    }
    .hero .hero-circle {
      opacity: 0.66;
      right: -22%;
    }
    .join {
      padding: 50px 0;
    }
    .join__inner h3 {
      margin-bottom: 5px;
    }
    .joinItem {
      width: calc(50% - 20px);
    }
    .request {
      padding: 70px 0 70px;
    }
    .request__inner-circle {
      width: 170px;
    }
    .requestForm {
      padding: 30px 20px;
    }
    .requestForm__row {
      margin: 0 -5px 24px;
    }
    .requestFormItem {
      margin: 5px;
      width: calc(100% - 10px);
    }
    .requestForm h3 {
      margin-bottom: 20px;
    }
    .requestForm h3 b {
      font-size: 16px;
      line-height: 20px;
    }
    .footer {
      padding: 30px 0 20px;
    }
    .footer__inner-row {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 24px 0 20px;
    }
    .footer__inner-col:nth-child(2) {
      display: none;
    }
    .footer__inner-col a:nth-child(n) {
      display: flex;
    }
    .footer__inner-main {
      width: calc(60% - 24px);
      margin: 0 0 0 24px;
      order: 2;
      max-width: 280px;
    }
    .footer__inner-main p {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 650px) {
    .footer__inner-row {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 24px 0 40px;
    }
    .footer__inner-col {
      width: calc(50% - 15px);
    }
    .footer__inner-main {
      width: 100%;
      margin: 30px 0 0 0;
      order: 2;
      max-width: unset;
    }
    .footer__inner-main p {
      max-width: unset;
    }
    .footer__inner-main .input {
      max-width: unset;
    }
    .footer__inner-main .button {
      width: 100%;
    }
  }

  @media (max-width: 540px) {
    h1 {
      font-size: 40px;
      line-height: 45px;
    }
    h2 {
      font-size: 40px;
      line-height: 45px;
    }
    h3 {
      font-size: 25px;
      line-height: 30px;
    }
    h5 {
      font-size: 16px;
      line-height: 22px;
    }
    body {
      font-size: 15px;
      line-height: 20px;
    }
    p.big {
      font-size: 16px;
      line-height: 22px;
    }
    .main {
      padding-top: 74.49px;
    }
    .check {
      font-size: 16px;
      line-height: 100%;
    }
    .check__box {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      margin: 0 10px 6px 0;
    }
    .check b {
      width: calc(100% - 28px);
    }
    .button {
      padding: 10px 22px 4px;
      font-size: 16px;
      line-height: 100%;
    }
    .header .header__inner {
      padding: 22px 0;
    }
    .header__inner {
      padding: 22px 0;
    }
    .header__inner-logo {
      width: 140px;
    }
    .nav__close {
      margin-bottom: 5px;
      width: 40px;
      height: 40px;
    }
    .nav__inner-row {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    .nav__inner-links {
      width: 100%;
      max-width: unset;
    }
    .nav__inner-social {
      width: 100%;
      max-width: unset;
      margin-top: 30px;
    }
    .nav__inner-social a {
      font-size: 20px;
      line-height: 100%;
    }
    .nav__inner-social a span {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    .nav__inner-col {
      max-width: unset;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .nav__inner .button.big {
      width: 100%;
      margin-top: 60px;
      order: 2;
    }
    .nav__inner .button.sm {
      width: 100%;
    }
    .nav__inner .input {
      width: 100%;
    }
    .hero__inner {
      min-height: calc(100vh - 75.49px);
      padding: 50px 0;
    }
    .hero__inner h1 {
      margin-bottom: 15px;
    }
    .hero__inner p {
      margin-bottom: 25px;
    }
    .join {
      padding: 30px 0 50px;
    }
    .join__inner-row {
      margin: 0;
    }
    .join__inner h3 {
      margin-bottom: 5px;
    }
    .joinItem {
      width: 100%;
      margin: 0 10px 30px 0;
      box-shadow: 10px 10px 0 #a7e2ee;
    }
    .joinItem:last-child {
      margin: 0;
    }
    .company {
      padding: 40px 0;
    }
    .company__inner-slider {
      margin-bottom: 30px;
    }
    .company__inner h3 {
      margin-bottom: 24px;
    }
    .companyItem {
      padding: 0 20px;
    }
    .companyItem img {
      width: 80%;
    }
    .events {
      padding: 30px 0;
    }
    .eventsItem {
      padding: 0 3px;
    }
    .partner__inner h2 {
      padding-bottom: 5px;
      margin-bottom: 30px;
    }
    .partner__inner h2::before {
      width: 50px;
      height: 2px;
    }
    .partner__inner .check {
      margin-bottom: 12px;
    }
    .partner__inner .button {
      margin-top: 20px;
    }
    .request {
      padding: 50px 0 50px;
    }
    .request__inner-circle {
      width: 140px;
    }
    .requestForm {
      padding: 20px;
    }
    .requestForm__row {
      margin-bottom: 18px;
    }
    .requestFormItem {
      width: calc(100% - 10px);
    }
    .requestForm h3 {
      margin-bottom: 16px;
    }
    .requestForm h3 b {
      font-size: 14px;
      line-height: 18px;
    }
    .requestForm .input__outer {
      margin-bottom: 16px;
    }
    .requestForm .input__outer.sm {
      width: 100%;
    }
    .requestForm .input__outer h5 {
      margin-bottom: 3px;
      font-size: 14px;
    }
  }

  @media (max-width: 440px) {
    .request .auto__container {
      padding: 0;
    }
  }

  /* Slider */
  .slick-loading .slick-list {
    // background: #fff url("./ajax-loader.gif") center center no-repeat;
  }

  /* Icons */
  @font-face {
  } /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -25px;
  }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
  }
  .slick-prev:before {
    content: "←";
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }

  .slick-next {
    right: -25px;
  }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
  }
  .slick-next:before {
    content: "→";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
  }

  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
  .slick-track:before,
  .slick-track:after {
    content: "";
    display: table;
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .events-wrapper .requestForm {
    padding: 55px 63px 32px;
    margin-bottom: 20px;
  }
  .events-wrapper .requestForm.empty {
    background-color: unset;
    padding: 0;
    padding-top: 20px;
    display: flex;
  }
  .events-wrapper .requestForm.submit {
    background-color: unset;
    padding: 0;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
  .events-wrapper .requestForm.submit .submit-wrapper {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .events-wrapper .requestForm.submit .submit-wrapper .submit-btn {
    margin: auto;
    width: 120px;
  }

  .events-wrapper .subheading-text {
    margin-bottom: 18px;
  }

  .events-wrapper .submit-success-msg {
    font-size: 17px;
    line-height: 19px;
    font-weight: 400;
    color: #000;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .events-wrapper .req-item .req-item-upper .req-item-img {
    width: 30% !important;
  }

  .events-wrapper .companies-section {
    padding-top: 0;
    padding-bottom: 0;
  }
  .events-wrapper .companies-section.industrial-tech .requestForm {
    background-color: #fff;
  }
  .events-wrapper
    .companies-section.industrial-tech
    .req-item
    .req-item-upper
    .req-item-img {
    width: 17% !important;
    padding-right: 24px;
  }
  .events-wrapper
    .companies-section.industrial-tech
    .req-item
    .req-item-content
    .check__box.adj {
    background-color: #fff;
  }
  .events-wrapper
    .companies-section.industrial-tech
    .req-item
    .req-item-content {
    background-color: #e2faff;
  }
  .events-wrapper
    .companies-section.industrial-tech
    .req-item
    .req-item-content:hover {
    background-color: #a7e2ee;
  }
  .events-wrapper
    .companies-section.industrial-tech
    .req-item
    .req-item-content.selected {
    background-color: #a7e2ee;
  }

  .ed-wrapper {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.15rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    flex: 1;
    background-color: #f2f2f2;
  }
  .ed-wrapper .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .ed-wrapper p {
    white-space: pre-wrap;
  }
  .ed-wrapper .section-heading {
    font-size: 3.7rem;
    font-weight: 400;
  }
  .ed-wrapper .section-heading.sm {
    font-size: 3.25rem;
  }
  .ed-wrapper .footer-image-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    width: 100%;
    margin-top: 100px;
  }
  .ed-wrapper .footer-image-outer .footer-image {
    width: 100%;
    position: relative;
  }
  .ed-wrapper .footer-image-outer .footer-image img {
    width: 100%;
  }
  .ed-wrapper .reg-closed-big-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ed-wrapper .reg-closed-big-container .reg-closed-divider {
    width: 100%;
    height: 1px;
    background-color: #7575753b;
  }
  .ed-wrapper .reg-closed-big-container .reg-closed-big {
    font-weight: 500;
    font-size: 26px;
    color: #757575;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    width: 100%;
  }
  .ed-wrapper .reg-closed-small {
    font-weight: 700;
    color: #757575;
  }
  .ed-wrapper .rsvp-button {
    display: flex;
    justify-content: center;
    --theme-primary-color: #61999d;
    background-color: #61999d;
    background-color: var(--theme-primary-color);
    color: #fff;
    padding: 18px 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    font-weight: 400;
    font-size: 1.15rem;
    border-radius: 8px;
    max-width: 172px;
    letter-spacing: 1.3px;
  }
  .ed-wrapper .rsvp-button:hover {
    // background-color: #487073;
  }
  .ed-wrapper .ed-block-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
  }
  .ed-wrapper .ed-block-container .ed-vertical-flex {
    display: flex;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
  }
  .ed-wrapper .ed-block-container .ed-vertical-flex .ed-left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ed-wrapper .ed-block-container .ed-vertical-flex .ed-right-rail {
    flex: none;
    max-width: 100%;
    width: 500px;
    display: block;
    flex-direction: column;
    box-sizing: border-box;
    display: block;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .ed-wrapper
    .ed-block-container
    .ed-vertical-flex
    .ed-right-rail
    .sticky-content {
    z-index: 1;
    min-height: auto;
    position: sticky;
    position: -webkit-sticky;
    min-height: auto;
    top: 150px;
    margin: auto;
    padding: 0;
    align-self: flex-start;
    margin-top: 50px;
  }
  .ed-wrapper .ed-main {
    width: 100%;
  }
  .ed-wrapper .horizontal-rule {
    background-color: #dcd9d0;
    width: 100%;
    height: 1px;
  }
  .ed-wrapper .header-bar {
    width: 100%;
    padding-top: 100px;
    background-color: #fff;
    padding: 20px 0;
    border-bottom: #a7a7b3 2px solid;
  }
  .ed-wrapper .header-bar .header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .ed-wrapper .header-bar .header-container .header-title {
    font-size: 1.6rem;
    letter-spacing: 2.2px;
    font-weight: 400;
  }
  .ed-wrapper .header-bar .header-container .header-logos {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 35px;
  }
  .ed-wrapper .header-bar .header-container .header-logos .header-logo {
    max-width: 156px;
  }
  .ed-wrapper .header-bar .header-container .header-logos .header-logo.lg {
    max-width: 170px;
  }
  .ed-wrapper .ed-hero {
    padding: 40px 0;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-location {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.4rem;
    color: #2f2b24;
    letter-spacing: -0.02rem;
    text-transform: capitalize;
    border-bottom: 1px solid #2f2b244d;
    flex: none;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 100%;
    display: flex;
    width: fit-content;
    margin: auto;
    margin-left: 0;
    margin-top: 30px;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-location img {
    background-color: #0000;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    display: flex;
    max-width: 100%;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-heading {
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-heading span {
    font-style: italic;
    font-weight: 400;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-subheading {
    font-size: 1.15rem;
    line-height: 160%;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .ed-wrapper .ed-hero .hero-info-container .hero-text {
    font-size: 1.15rem;
    line-height: 160%;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .ed-wrapper .ed-hero .hero-info-container .expect-bullets {
    margin: 40px 0;
    padding-left: 20px;
  }
  .ed-wrapper .ed-hero .hero-info-container .expect-bullets li {
    margin-bottom: 10px;
    list-style-type: disc;
  }
  .ed-wrapper .ed-hero .hero-info-container .expect-disclaimer {
    margin-top: 30px;
    margin-bottom: 0px;
    font-style: italic;
  }
  .ed-wrapper .ed-event-info {
    width: 100%;
    background-color: #fff;
    border: #dcd9d0 2px solid;
    border-radius: 20px;
    z-index: 10000;
    border-width: 1px;
    border-color: #dcd9d0;
    font-size: 1.15rem;
    line-height: 160%;
    padding: 40px 20px;
    align-items: stretch;
    padding: 2rem;
  }
  .ed-wrapper .ed-event-info .event-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
  }
  .ed-wrapper .ed-event-info .event-inner .event-image {
    width: 100%;
    width: 100%;
    padding-top: 56.75%;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .ed-wrapper .ed-event-info .event-inner .event-image img {
    object-fit: cover;
    z-index: 10;
    position: absolute;
    inset: 0%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .ed-wrapper .ed-event-info .event-inner .event-info-container {
    width: 100%;
  }
  .ed-wrapper
    .ed-event-info
    .event-inner
    .event-info-container
    .event-details-heading {
    font-size: 1.15rem;
    font-weight: 700;
    letter-spacing: 2.5px;
    margin-bottom: 20px;
  }
  .ed-wrapper .ed-event-info .event-inner .event-info-container .event-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    margin-bottom: 20px;
  }
  .ed-wrapper
    .ed-event-info
    .event-inner
    .event-info-container
    .event-info
    .event-info-text {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .ed-wrapper
    .ed-event-info
    .event-inner
    .event-info-container
    .event-info
    .event-info-text
    .start {
    font-weight: 700;
    margin-right: 8px;
  }
  .ed-wrapper .ed-expect {
    padding: 40px 0;
  }
  .ed-wrapper .ed-expect .expect-title {
    margin-bottom: 20px;
  }
  .ed-wrapper .ed-expect .expect-text {
    margin-bottom: 20px;
  }
  .ed-wrapper .ed-expect .expect-bullets {
    margin: 40px 0;
    padding-left: 20px;
  }
  .ed-wrapper .ed-expect .expect-bullets li {
    margin-bottom: 10px;
    list-style-type: disc;
  }
  .ed-wrapper .ed-expect .expect-disclaimer {
    margin-top: 20px;
    font-weight: 700;
    margin-bottom: 60px;
  }
  .ed-wrapper .ed-expect .ed-faqs {
    margin-top: 50px;
  }
  .ed-wrapper .ed-expect .ed-faqs .ed-faq-title {
    margin-bottom: 20px;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq {
    border-bottom: 2px solid #7c8c93;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq.first {
    border-top: 2px solid #7c8c93;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq .faq-head {
    padding: 25px 60px 25px 0;
    cursor: pointer;
    transition: 0.3s ease;
    position: relative;
    font-weight: 600;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq .faq-head.active::before {
    transform: translateY(-40%) rotate(-45deg);
  }
  .ed-wrapper .ed-expect .ed-faqs .faq .faq-head::before {
    position: absolute;
    top: 50%;
    right: 20px;
    content: "";
    width: 16px;
    height: 16px;
    border-top: 3px solid var(--theme-primary-color);
    border-right: 3px solid var(--theme-primary-color);
    transform: translateY(-70%) rotate(135deg);
    transition: 0.3s ease;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq .faq-body {
    max-height: 0;
    opacity: 0;
    transition: 0.4s ease;
    overflow: hidden;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq .faq-body.active {
    padding: 0 0 30px 0;
    opacity: 1;
    max-height: 400px;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq p {
    font-size: 1.15rem;
    line-height: 160%;
  }
  .ed-wrapper .ed-expect .ed-faqs .faq p a {
    display: inline-block;
    color: #015637;
    font-weight: 500;
  }
  .ed-wrapper .footer-spacer {
    height: 100px;
  }
  .ed-wrapper .ed-footer {
    background-color: #fff;
    border-top: 2px solid #a7a7b3;
    min-height: 300px;
  }

  @keyframes scale-in {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .modal.scale-in .modal-dialog {
    animation: scale-in 0.3s ease-out;
  }

  .modal.scale-out .modal-dialog {
    animation: scale-out 0.3s ease-out;
  }

  @keyframes scale-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }

  .rsvp-modal {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
  .rsvp-modal .form-control {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .rsvp-modal .form-control:focus {
    border-color: #61999d;
    outline: 0;
    box-shadow: none;
  }
  .rsvp-modal .check__box span {
    border: 2px solid #61999d;
  }
  .rsvp-modal .check__box input:checked ~ span {
    background-color: #61999d;
  }
  .rsvp-modal .legal-notice {
    text-align: center;
    color: #000;
    font-size: 10px !important;
    max-width: 360px;
    margin: auto;
    margin-bottom: 0 !important;
  }
  .rsvp-modal .legal-notice a {
    font-size: 10px !important;
  }
  .rsvp-modal .modal-content {
    padding: 50px;
    padding-left: 38px;
    padding-right: 38px;
    border: #dcd9d0 2px solid;
    border-radius: 20px;
    z-index: 10000;
    border-width: 1px;
    border-color: #dcd9d0;
  }
  .rsvp-modal .rsvp-modal-title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }
  .rsvp-modal .rsvp-modal-title.sm {
    font-size: 2rem;
  }
  .rsvp-modal .rsvp-submitted-text {
    font-size: 1.1rem;
  }
  .rsvp-modal .rsvp-modal-close {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: unset;
    top: 10px;
    right: 14px;
    margin: auto;
    transition: color 0.2s;
    font-size: 22px;
    cursor: pointer;
  }
  .rsvp-modal .rsvp-modal-close:hover {
    color: #ba1414;
  }
  .rsvp-modal .register-button {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    background-color: #61999d;
    background-color: var(--theme-primary-color);
    color: #fff;
    padding: 12px 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    font-weight: 400;
    font-size: 22px;
    border-radius: 8px;
    width: 100%;
    letter-spacing: 1.3px;
  }
  .rsvp-modal .register-button:hover {
    // background-color: #487073;
  }
  .rsvp-modal .fields-column {
    display: flex;
    flex-direction: column;
  }
  .rsvp-modal .fields-row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
  .rsvp-modal .rsvp-field:not(.first) {
    margin-top: 16px;
  }
  .rsvp-modal .rsvp-field.last {
    margin-bottom: 16px;
  }
  .rsvp-modal .rsvp-field .rsvp-field-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .rsvp-modal .rsvp-field .rsvp-field-label .asterisk {
    margin-left: 2px;
    font-size: 18px;
    line-height: 1;
  }
  .rsvp-modal .rsvp-checkbox-container {
    margin-top: 16px;
  }
  .rsvp-modal .check-label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 1.5;
  }
  .rsvp-modal .check-label .inline-link {
    display: inline-block;
    font-size: 12px;
    color: #015637;
    line-height: 1.5;
  }
  .rsvp-modal .required-field-msg {
    color: #ba1414;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
    height: 30px;
  }
  .rsvp-modal .required-field-msg.bottom {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .modal-open .ed-main {
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }

  .mob-only {
    display: none;
  }

  @media (max-width: 992px) {
    .mob-only {
      display: block;
    }
    .ed-wrapper .ed-block-container .ed-vertical-flex .ed-right-rail {
      display: none;
    }
    .ed-wrapper .horizontal-rule.hero {
      display: none;
    }
    .ed-wrapper .header-bar .header-container {
      justify-content: center;
    }
    .ed-wrapper .header-bar .header-container .header-logos {
      justify-content: space-between;
      width: 100%;
      flex-wrap: nowrap;
      /* Prevents wrapping of logos */
    }
    .ed-wrapper .header-bar .header-container .header-logos.right {
      display: none;
    }
    .ed-wrapper .header-bar .header-container .header-logos .header-logo {
      flex: 1 1 auto;
      /* Allows logos to grow and shrink equally */
    }
    .ed-wrapper .ed-hero {
      padding-bottom: 40px;
    }
    .ed-wrapper .ed-hero .hero-info-container {
      text-align: center;
    }
    .ed-wrapper .ed-hero .hero-info-container .hero-location {
      margin-left: auto;
    }
    .ed-wrapper .ed-hero .hero-info-container .expect-bullets {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
    .ed-wrapper .ed-hero .hero-info-container .expect-disclaimer {
      width: 80%;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 0;
    }
    .ed-wrapper .ed-hero .hero-info-container .rsvp-button {
      margin-left: auto;
      margin-right: auto;
    }
    @keyframes scale-in {
    }
    @keyframes scale-out {
    }
  }

  .industrial-tech :target {
    scroll-margin-top: 80px;
    /* Total height of the navbar including padding */
  }

  .industrial-tech .request {
    padding: 0;
  }
  .industrial-tech .request.top {
    padding-top: 18px;
  }
  .industrial-tech .request.last {
    padding-bottom: 62px;
    padding-top: 20px;
  }
  .industrial-tech .request.last .input input {
    background-color: #ffffff;
  }
  .industrial-tech .request.final {
    padding-bottom: 43px;
  }

  .industrial-tech .input textarea {
    background-color: #fff;
  }

  .industrial-tech .input.choose-file-input-outer {
    justify-content: flex-start;
  }

  .industrial-tech .requestForm {
    padding: 44px 64px 22px;
    background-color: unset;
  }
  .industrial-tech .requestForm h3 {
    margin-bottom: 0;
  }
  .industrial-tech .requestForm .request-container {
    background-color: #fff;
    padding: 30px 16px 0px 34px;
  }
  .industrial-tech .requestForm .input__outer {
    margin-bottom: 25px;
  }

  .industrial-tech .overview-section {
    width: 100%;
    background-color: #fff;
  }

  .industrial-tech .topics-section {
    width: 100%;
    background-color: #e2faff;
    padding: 30px;
    padding-top: 46px;
    padding-bottom: 46px;
  }
  .industrial-tech .topics-section .auto__container {
    max-width: 947px;
  }
  .industrial-tech .topics-section .topic-container {
    color: #002b4b;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: auto;
  }
  .industrial-tech .topics-section .topic-container .title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .industrial-tech .topics-section .topic-container .desc {
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .industrial-tech .topics-section .topic-container .action-btn {
    border-radius: 6px;
    background-color: #a7e2ee;
    padding: 6px;
    padding-left: 37px;
    padding-right: 37px;
    font-size: 22px;
    height: 38px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.15s ease;
  }
  .industrial-tech .topics-section .topic-container .action-btn div {
    transform: translateY(2px);
  }
  .industrial-tech .topics-section .topic-container .action-btn:hover {
    background-color: #abc6e7;
  }
  @media (max-width: 991px) {
    .industrial-tech .topics-section .topic-container .action-btn {
      margin-bottom: 85px !important;
      font-size: 2rem;
      height: 60px;
    }
    .industrial-tech .topics-section .topic-container .action-btn div {
      transform: translateY(5px);
    }
  }

  .industrial-tech .section-header-text {
    max-width: 947px;
    margin: 0 auto;
    padding: 0 30px 30px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    background-color: #fff;
  }
  .industrial-tech .section-header-text .title {
    font-size: 30px;
    font-weight: 400;
  }
  .industrial-tech .section-header-text .sub-text {
    font-size: 18px;
  }

  .industrial-tech .request-heading {
    font-size: 38px;
    width: 100%;
  }

  .industrial-tech .request-subheading {
    font-weight: 400;
    margin-bottom: 17px;
    width: 100%;
  }

  .industrial-tech .request-heading-sm {
    font-size: 28px;
    width: 100%;
    margin-bottom: 16px;
  }

  .industrial-tech .form-subbox {
    margin-top: 9px;
  }

  .industrial-tech .hero__inner p {
    margin-bottom: 25px;
  }

  .industrial-tech .intro-text {
    font-size: 46px;
    line-height: 1;
  }
  .industrial-tech .intro-text.sm {
    font-size: 22px;
    font-weight: 300;
  }

  .industrial-tech .span-leadspace-home__spanner {
    border-bottom-color: #00a6cb00;
  }

  .industrial-tech .hero__inner {
    min-height: calc(97vh - 442px);
    min-height: calc(50vh);
    min-height: 0;
    padding-top: 94px;
    padding-bottom: 63px;
  }

  .industrial-tech .events {
    padding: 52px 0;
    padding-top: 41px !important;
  }

  .industrial-tech .events .auto__container {
    display: block;
    z-index: 2;
  }

  .industrial-tech .hero-circle {
    top: 15%;
    right: 2%;
    width: 44%;
    max-width: 251px;
    z-index: 1;
    top: 15%;
    right: 10%;
    width: 44%;
    max-width: 259px;
    z-index: 1;
  }

  .industrial-tech .short-desc {
    font-weight: 700;
  }

  .industrial-tech .long-desc {
    font-weight: 400;
  }

  .industrial-tech .requestFormItem__inner {
    padding-bottom: 50%;
  }

  .industrial-tech .company {
    padding: 20px 0;
  }
  .industrial-tech .company .company__inner h3 {
    font-size: 36px;
    margin-bottom: 36px;
  }

  .industrial-tech .company-img {
    position: absolute;
    top: 7%;
    right: 5%;
    width: 20%;
    max-width: 105px;
  }

  .industrial-tech .req-item {
    position: relative;
    width: 100%;
    height: fit-content;
    margin-bottom: 8px;
  }
  .industrial-tech .req-item.event {
    padding-right: 8px;
  }
  .industrial-tech .req-item.event .req-item-upper .req-item-img {
    width: 20%;
    padding-right: 8px;
    margin-right: 8px;
  }
  .industrial-tech .req-item .req-item-content {
    padding: 15px 19px 12px;
    background-color: #fff;
    transition: 0.3s ease;
  }
  .industrial-tech .req-item input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  .industrial-tech .req-item input[type="checkbox"]:hover ~ .req-item-content {
    background-color: #d2f7ff;
  }
  .industrial-tech
    .req-item
    input[type="checkbox"]:checked
    ~ .req-item-content {
    background-color: #a7e2ee;
  }
  .industrial-tech .req-item .req-item-upper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .industrial-tech .req-item .req-item-upper .req-item-img {
    width: 17%;
    padding-right: 24px;
  }
  @media (max-width: 750px) {
    .industrial-tech .req-item .req-item-upper .req-item-img {
      width: 23%;
      padding-right: 11px;
    }
  }
  .industrial-tech .req-item .req-item-upper .req-item-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .industrial-tech .req-item .req-item-upper .req-item-header h5 {
    margin-bottom: 0;
  }
  .industrial-tech .req-item .req-item-upper .req-item-header .sm.short-desc {
    margin-bottom: 1px;
    font-weight: 500;
  }
  .industrial-tech .req-item .req-item-upper .req-item-header .sm.long-desc {
    color: #2b2b2b;
  }
  .industrial-tech .req-item .req-item-lower {
    width: 100%;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .request-section {
    transform: translateY(-80px);
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2 .pink-btn {
    margin-top: 0;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .form-invalid-msg {
    text-align: center;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2 .hero-row-lower {
    transform: translateY(-61px);
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2 .hero-container {
    padding-top: 0;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2 .hero__inner h1 {
    line-height: 88px;
    text-align: center;
    max-width: 1080px;
    margin: auto;
    margin-bottom: 27px;
    text-shadow: 1px 4px 4px #00000061;
  }
  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .hero__inner
    h1.greeting {
    font-size: 60px;
    line-height: 60px;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .header-text-container
    .header-text {
    max-width: 800px;
    margin: auto;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2 .hero__inner {
    min-height: 563px;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .option-container
    .content-container {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 20px;
  }
  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .option-container
    .content-container
    .startup-title {
    font-size: 18px;
    font-weight: 600;
  }
  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .option-container
    .content-container
    .startup-short-desc {
    font-weight: 500;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .options-list
    .option-container {
    min-height: unset;
  }

  .industrial-tech-new.specificity-adder-1.specificity-adder-2
    .option-container
    .outer-container
    .checkbox-container {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  .dealmakers-ib .requestForm {
    padding: 12px 64px 22px;
  }
  .dealmakers-ib .requestForm.first {
    padding-top: 40px;
  }
  .dealmakers-ib .requestForm.lg-pt {
    padding-top: 34px;
  }

  .sis-omni .section-header-text {
    padding-top: 8px;
  }

  .sis-omni .sis-omni-logo {
    width: 90%;
    max-width: 300px;
    margin: auto;
    text-align: center;
    margin-bottom: 8px;
  }

  .sis-omni .center-last-row {
    display: flex;
    justify-content: center;
  }
  .sis-omni .center-last-row .action-btn {
    margin-bottom: 4px !important;
  }

  .sis-omni .topics-section .topic-container .desc.smaller {
    font-size: 18px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .sis-omni .hero__inner {
    padding-bottom: 115px;
  }

  .sis-omni .req-item .req-item-content {
    padding-left: 30px;
  }
  .sis-omni .req-item .req-item-content.alt {
    background-color: #e2faff;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 13px;
  }
  .sis-omni .req-item .req-item-content.alt.selected {
    background-color: #a7e2ee;
  }
  .sis-omni .req-item .req-item-content.alt .check__box.adj {
    background-color: #fff;
  }
  .sis-omni .req-item .req-item-content.alt:hover {
    background-color: #a7e2ee;
  }

  .sis-omni .check__box.adj {
    margin-bottom: 0;
  }

  .sis-omni .request.last {
    padding-top: 0;
  }

  .sis-omni .company {
    padding: 31px 0;
  }

  .sis-omni .about-you-container {
    background-color: #fff;
    padding: 30px 30px 30px 30px;
  }
  .sis-omni .about-you-container .input input {
    background-color: #f3f3f3 !important;
  }

  .sis-omni .submit-btn {
    margin-top: 22px;
  }

  @media (max-width: 991px) {
    .sis-omni .topic-container img {
      margin-bottom: 52px !important;
    }
  }

  .req-accordion {
    padding-bottom: 22px;
  }
  .req-accordion a {
    display: inline;
    text-decoration: underline !important;
    font-size: unset;
    letter-spacing: unset;
    line-height: unset;
    color: #125bcf;
  }
  .req-accordion p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .req-accordion h4 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 2px;
    line-height: 1;
  }

  .learn-more-accordion-toggle {
    padding-bottom: 20px;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .pl-1 {
    padding-left: 1rem;
  }

  .sis-delegate .action-btn {
    margin-bottom: 57px !important;
  }

  .sis-delegate .center-last-row {
    display: flex;
    justify-content: center;
  }
  .sis-delegate .center-last-row .action-btn {
    margin-bottom: 4px !important;
  }

  .sis-delegate .topics-section .topic-container .desc.smaller {
    font-size: 18px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .sis-delegate .hero__inner {
    padding-bottom: 115px;
  }

  .sis-delegate .banner-img {
    width: 100%;
    max-width: 947px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 55px;
  }

  .sis-delegate .section-header-text {
    padding-top: 4px;
  }

  .ces-wrapper .disclaimer {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 77px;
  }

  .ces-wrapper .preface-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ces-wrapper .preface-container .preface-text {
    font-size: 22px;
    line-height: 1.12;
    text-align: center;
    width: 90%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 30px;
  }

  .ces-wrapper .hero__inner h1 {
    font-size: 85px;
  }

  .ces-wrapper .hero__inner p {
    font-size: 22px;
    line-height: 1.12;
  }

  .ces-wrapper .hero__inner .button.btn-std {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .ces-wrapper .hero__inner .button.btn-std:not(.disabled):hover {
    border-color: #115381;
    background-color: #115381;
  }

  .ces-wrapper #events:target {
    padding-top: 165px;
    /* Adjust to match your navbar height */
    margin-top: -70px;
    /* Counteract padding to keep layout intact */
  }

  .ces-wrapper #events {
    transition: padding-top 0.3s;
  }

  .ces-wrapper .req-item.extended .req-item-content {
    padding-left: 30px;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .ces-wrapper .req-item.extended .req-item-content .req-item-header {
    cursor: pointer;
  }
  .ces-wrapper .req-item.extended .req-item-content .req-item-header h5 {
    font-size: 20px;
  }
  .ces-wrapper .req-item.extended .req-item-content .short-desc {
    font-size: 16px;
    line-height: 1.6;
  }
  .ces-wrapper .req-item.extended .req-item-content .short-desc .divider {
    margin-left: 8px;
    margin-right: 8px;
  }
  .ces-wrapper .req-item.extended .req-item-content .long-desc {
    font-size: 16px;
    line-height: 1.3;
  }
  .ces-wrapper .req-item.extended .req-item-content.alt {
    background-color: #e2faff;
    margin-bottom: 13px;
  }
  .ces-wrapper .req-item.extended .req-item-content.alt.selected {
    background-color: #a7e2ee;
  }
  .ces-wrapper .req-item.extended .req-item-content.alt .check__box.adj {
    background-color: #fff;
  }
  .ces-wrapper .req-item.extended .req-item-content.alt:hover {
    background-color: #a7e2ee;
  }

  .ces-wrapper .check__box.adj {
    margin-bottom: 0;
  }

  .ces-wrapper .company {
    padding: 70px 0;
  }
  .ces-wrapper .company__inner-slider {
    margin-bottom: 20px;
  }
  .ces-wrapper .company__inner h3 {
    margin-bottom: 40px;
  }
  .ces-wrapper .companyItem.top img {
    max-height: 70px;
    height: 70px;
  }

  .ces-wrapper .companyItem.top {
    height: 70px;
  }

  .ces-wrapper .companyItem.top img {
    height: 70px;
  }

  .ces-wrapper.redesign {
    background-color: #000000;
  }
  .ces-wrapper.redesign .request {
    background-color: #000000;
  }
  .ces-wrapper.redesign .options-list {
    display: flex;
    flex-direction: column;
  }
  .ces-wrapper.redesign .options-list .option-heading-text {
    color: #fff;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 24px;
  }
  .ces-wrapper.redesign .options-list .option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    min-height: 200px;
    border-radius: 8px;
    margin-bottom: 36px;
  }
  .ces-wrapper.redesign .options-list .option-container .option-header {
    width: 100%;
    height: auto;
    background-image: url("https://i.imgur.com/CC3UZue.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 116px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 26px;
    padding-left: 56px;
    padding-right: 56px;
    flex-direction: column;
  }
  .ces-wrapper.redesign .options-list .option-container .option-header.logo {
    flex-direction: row;
    justify-content: space-between;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .left {
    width: 80%;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right
    .logo-title {
    font-size: 16px;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
    width: 100%;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right
    .logo-img {
    width: 100%;
  }
  .ces-wrapper.redesign .options-list .option-container .option-header .text {
    color: #fff;
    font-size: 43px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    margin: auto;
    line-height: 1;
    transform: translateY(5px);
    width: 100%;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-header
    .text.prefix {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .ces-wrapper.redesign .options-list .option-container .option-body {
    padding-top: 50px;
    padding-bottom: 33px;
    padding-left: 56px;
    padding-right: 56px;
    display: flex;
    flex-direction: column;
    color: #000000;
    font-size: 26px;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container
    .date-time-container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    text-align: left;
    line-height: 1;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container
    .date-time-container
    .date.slim {
    font-weight: 300;
    text-align: right;
  }
  .ces-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .description {
    padding-top: 40px;
    text-align: left;
    font-weight: 400;
    line-height: 1.4;
  }
  .ces-wrapper.redesign .hero__inner {
    min-height: 700px;
  }
  .ces-wrapper.redesign .hero__inner h1 {
    line-height: 88px;
    text-align: center;
    max-width: 1080px;
    margin: auto;
    margin-bottom: 27px;
  }
  .ces-wrapper.redesign .hero-row-lower {
    transform: translateY(-116px);
  }
  .ces-wrapper.redesign .hero-container {
    padding-top: 74px;
  }
  .ces-wrapper.redesign .pink-btn {
    background-color: #ff2f92;
    font-size: 28px;
    color: #fff;
    padding: 100px;
    padding-top: 25px;
    padding-bottom: 15px;
    border-radius: 8px;
    margin: auto;
    margin-top: 40px;
  }
  .ces-wrapper.redesign .pink-btn:hover {
    background-color: #df2e84;
    border-color: #df2e84;
  }
  .ces-wrapper.redesign .submit-success-msg {
    font-size: 23px;
    line-height: 1.5;
    color: #f10073;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .ces-wrapper.redesign .requestForm {
    max-width: unset;
    border-radius: 8px;
  }
  .ces-wrapper.redesign .requestForm h3 {
    font-size: 38px;
    margin-bottom: 34px;
  }
  .ces-wrapper.redesign .requestForm h3:not(:first-child) {
    margin-top: 14px;
  }
  .ces-wrapper.redesign .requestForm h5.partner-text {
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 25px;
    margin-top: 53px;
    color: #000000;
  }
  .ces-wrapper.redesign .check {
    margin-top: 20px;
  }
  .ces-wrapper.redesign .check__box {
    margin: 0 39px 9px 0;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
  }
  .ces-wrapper.redesign .check__box span {
    border: 2px solid #717171;
  }
  .ces-wrapper.redesign .check-label {
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
    transform: translateY(-2px);
  }
  .ces-wrapper.redesign .check__box input:checked ~ span {
    background-color: #ff2f92;
  }
  .ces-wrapper.redesign .cap-width {
    width: 100%;
    max-width: 920px;
    margin: auto;
  }
  .ces-wrapper.redesign .cap-width.slightly-wider {
    max-width: 1000px;
  }
  .ces-wrapper.redesign .header-img {
    width: 100%;
    padding: 10px;
    max-width: 600px;
  }
  .ces-wrapper.redesign .header-text-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ces-wrapper.redesign .header-text-container .header-text {
    color: #fff;
    font-size: 27px;
    line-height: 33px;
    padding: 12px;
  }
  .ces-wrapper.redesign .hero__bg {
    top: -80px;
  }
  .ces-wrapper.redesign .hero__bg img {
    width: 100%;
    object-fit: cover;
    object-fit: fill;
    object-position: unset;
  }
  .ces-wrapper.redesign .hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 80px;
  }
  .ces-wrapper.redesign .hero-btns .hero-btn .btn-std.pink {
    background-color: #ff2f92;
    font-size: 28px;
    color: #fff;
    padding-top: 25px;
    padding-bottom: 15px;
    text-align: center;
  }
  .ces-wrapper.redesign .hero-btns .hero-btn .btn-std.pink:hover {
    background-color: #df2e84;
  }
  .ces-wrapper.redesign .hero-btns .hero-btn:first-child {
    margin-right: 22px;
  }
  @media (max-width: 767px) {
    .ces-wrapper.redesign .show-mob {
      display: flex;
    }
    .ces-wrapper.redesign .hide-mob {
      display: none;
    }
    .ces-wrapper.redesign .hero__inner h1 {
      font-size: 74px;
    }
    .ces-wrapper.redesign .hero-btns {
      flex-direction: column;
      justify-content: center;
      margin-top: 68px;
      margin-bottom: 150px;
    }
    .ces-wrapper.redesign .hero-btns .hero-btn {
      margin: auto;
      margin-bottom: 70px;
    }
    .ces-wrapper.redesign .hero-btns .hero-btn:first-child {
      margin-right: auto;
    }
    .ces-wrapper.redesign .header-text-container .header-text {
      margin-top: 50px;
    }
    .ces-wrapper.redesign .options-list .option-container {
      width: 92%;
      margin: auto;
      margin-bottom: 36px;
      /* margin-left: 20px; */
      /* margin-right: 20px; */
    }
    .ces-wrapper.redesign label.input__outer.sm:not(:first-child) {
      margin-top: unset !important;
    }
    .ces-wrapper.redesign label.input__outer.sm {
      margin-bottom: 42px;
    }
    .ces-wrapper.redesign .requestForm h3 {
      margin-top: 40px !important;
    }
    .ces-wrapper.redesign .requestForm .input__outer.sm {
      width: 100%;
    }
    .ces-wrapper.redesign .requestForm .input__outer h5 {
      margin-bottom: 3px;
      font-size: 20px;
    }
    .ces-wrapper.redesign .check {
      margin-top: 55px;
    }
    .ces-wrapper.redesign .check-label {
      font-size: 20px;
      line-height: 1.3;
    }
    .ces-wrapper.redesign .requestForm h5.partner-text {
      margin-bottom: 61px;
      margin-top: 88px;
      font-size: 26px;
      line-height: 1.3;
    }
    .ces-wrapper.redesign .form-invalid-msg {
      font-size: 20px;
      margin-top: 24px;
      text-align: center;
    }
    .ces-wrapper.redesign .requestForm {
      padding: 34px 26px 32px;
    }
    .ces-wrapper.redesign .options-list .option-container {
      width: 100%;
    }
    .ces-wrapper.redesign .options-list .option-container .option-header {
      padding-left: 26px;
      padding-right: 26px;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-header
      .text.prefix {
      font-size: 30px;
    }
    .ces-wrapper.redesign .options-list .option-container .option-header.logo {
      flex-direction: column;
      justify-content: center;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .left {
      width: 100%;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .right {
      width: 50%;
      padding-top: 26px;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .right
      .logo-title {
      font-size: 22px;
      margin-bottom: 5px;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .ces-wrapper.redesign .options-list .option-container .option-body {
      padding-left: 26px;
      padding-right: 26px;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container
      .date-time-container
      .date {
      margin-bottom: 15px;
      text-align: left !important;
    }
    .ces-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container
      .date-time-container:last-child {
      margin-top: 30px;
    }
  }

  .ces-header {
    position: absolute;
    background-color: #002b4900;
  }

  .unga-wrapper .disclaimer {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 77px;
  }

  .unga-wrapper .preface-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .unga-wrapper .preface-container .preface-text {
    font-size: 22px;
    line-height: 1.12;
    text-align: center;
    width: 90%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 30px;
  }

  .unga-wrapper .hero__inner h1 {
    font-size: 75px;
  }

  .unga-wrapper .hero__inner p {
    font-size: 22px;
    line-height: 1.12;
  }

  .unga-wrapper .hero__inner .button.btn-std {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .unga-wrapper .hero__inner .button.btn-std:not(.disabled):hover {
    border-color: #115381;
    background-color: #115381;
  }

  .unga-wrapper #events:target {
    padding-top: 165px;
    /* Adjust to match your navbar height */
    margin-top: -70px;
    /* Counteract padding to keep layout intact */
  }

  .unga-wrapper #events {
    transition: padding-top 0.3s;
  }

  .unga-wrapper .req-item.extended .req-item-content {
    padding-left: 30px;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .unga-wrapper .req-item.extended .req-item-content .req-item-header {
    cursor: pointer;
  }
  .unga-wrapper .req-item.extended .req-item-content .req-item-header h5 {
    font-size: 20px;
  }
  .unga-wrapper .req-item.extended .req-item-content .short-desc {
    font-size: 16px;
    line-height: 1.6;
  }
  .unga-wrapper .req-item.extended .req-item-content .short-desc .divider {
    margin-left: 8px;
    margin-right: 8px;
  }
  .unga-wrapper .req-item.extended .req-item-content .long-desc {
    font-size: 16px;
    line-height: 1.3;
  }
  .unga-wrapper .req-item.extended .req-item-content.alt {
    background-color: #e2faff;
    margin-bottom: 13px;
  }
  .unga-wrapper .req-item.extended .req-item-content.alt.selected {
    background-color: #a7e2ee;
  }
  .unga-wrapper .req-item.extended .req-item-content.alt .check__box.adj {
    background-color: #fff;
  }
  .unga-wrapper .req-item.extended .req-item-content.alt:hover {
    background-color: #a7e2ee;
  }

  .unga-wrapper .check__box.adj {
    margin-bottom: 0;
  }

  .unga-wrapper .company {
    padding: 70px 0;
  }
  .unga-wrapper .company__inner-slider {
    margin-bottom: 20px;
  }
  .unga-wrapper .company__inner h3 {
    margin-bottom: 40px;
  }
  .unga-wrapper .companyItem.top img {
    max-height: 70px;
    height: 70px;
  }

  .unga-wrapper .companyItem.top {
    height: 70px;
  }

  .unga-wrapper .companyItem.top img {
    height: 70px;
  }

  .unga-wrapper.redesign {
    background-color: #fff;
  }
  .unga-wrapper.redesign .pink-bg-section {
    padding-top: 60px;
    background-color: #f8a7cd;
    width: 100%;
  }
  .unga-wrapper.redesign .request {
    background-color: #fff;
  }
  .unga-wrapper.redesign .option-header {
    width: 100%;
    height: auto;
    // background-image: url(/public/images/unga/bg-pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 116px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 26px;
    padding-left: 56px;
    padding-right: 56px;
    flex-direction: column;
  }
  .unga-wrapper.redesign .options-list {
    display: flex;
    flex-direction: column;
  }
  .unga-wrapper.redesign .options-list .option-heading-text {
    color: #eb1bb9;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 24px;
  }
  .unga-wrapper.redesign .options-list .option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    min-height: 200px;
    border-radius: 8px;
    margin-bottom: 46px;
  }
  .unga-wrapper.redesign .options-list .option-container .option-header {
    width: 100%;
    height: auto;
    // background-image: url(/public/images/unga/bg-pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 116px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 26px;
    padding-left: 56px;
    padding-right: 56px;
    flex-direction: column;
  }
  .unga-wrapper.redesign .options-list .option-container .option-header.logo {
    flex-direction: row;
    justify-content: space-between;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .left {
    width: 80%;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right
    .logo-title {
    font-size: 16px;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
    width: 100%;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right
    .logo-img {
    width: 100%;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header.logo
    .right
    .logo-img.mt {
    margin-top: 10px;
  }
  .unga-wrapper.redesign .options-list .option-container .option-header .text {
    color: #fff;
    font-size: 43px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    line-height: 1;
    transform: translateY(5px);
    width: 100%;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header
    .text.prefix {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-header
    .text.postfix {
    text-transform: none;
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0.6px;
  }
  .unga-wrapper.redesign .options-list .option-container .option-body {
    padding-top: 50px;
    padding-bottom: 33px;
    padding-left: 56px;
    padding-right: 56px;
    display: flex;
    flex-direction: column;
    color: #000000;
    font-size: 26px;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container
    .date-time-container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    text-align: left;
    line-height: 1;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container
    .date-time-container
    .date.slim {
    font-weight: 300;
    text-align: right;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .upper-container
    .date-time-container
    .location {
    text-align: right;
    font-weight: 500;
    text-transform: uppercase;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .description {
    padding-top: 40px;
    text-align: left;
    font-weight: 400;
    line-height: 1.4;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .description
    p:not(:first-child) {
    margin-top: 10px;
  }
  .unga-wrapper.redesign
    .options-list
    .option-container
    .option-body
    .description
    p
    a {
    display: inline;
    color: #8748f0;
    font-weight: 400;
    line-height: 1.4;
    font-size: 26px;
    letter-spacing: normal;
  }
  .unga-wrapper.redesign .hero__inner {
    min-height: unset;
  }
  .unga-wrapper.redesign .hero__inner h1 {
    line-height: 88px;
    text-align: center;
    max-width: unset;
    width: 100%;
    margin: auto;
    margin-bottom: 27px;
    margin-left: 0;
    text-align: left;
  }
  .unga-wrapper.redesign .hero-container {
    padding-top: 74px;
    padding-bottom: 75px;
  }
  .unga-wrapper.redesign .hero-container .hero-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 850px;
    margin: auto;
    margin-bottom: 46px;
    margin-bottom: 55px;
    margin-left: 0;
  }
  .unga-wrapper.redesign .hero-container .hero-logos .lm-logo {
    max-width: 180px;
    transform: translateY(-10px);
  }
  .unga-wrapper.redesign .hero-container .hero-logos .un-logo {
    max-width: 110px;
  }
  .unga-wrapper.redesign .hero-container .hero-logos .sis-logo {
    max-width: 300px;
  }
  .unga-wrapper.redesign .pink-btn {
    background: #fe14a8;
    background: linear-gradient(90deg, #fe14a8 0%, #a636f7 100%);
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    padding: 100px;
    padding-top: 25px;
    padding-bottom: 15px;
    border-radius: 42px;
    border: 0;
    margin: auto;
    margin-top: 17px;
    min-width: 300px;
    text-align: center;
    filter: brightness(1);
  }
  .unga-wrapper.redesign .pink-btn.no-grad {
    background: #fe14a8;
  }
  .unga-wrapper.redesign .pink-btn.simple {
    border-radius: 12px;
    padding: 80px;
    padding-top: 20px;
    padding-bottom: 10px;
    min-width: 270px;
  }
  .unga-wrapper.redesign .pink-btn:hover {
    filter: brightness(0.92);
    opacity: 1 !important;
  }
  .unga-wrapper.redesign .submit-success-msg {
    font-size: 23px;
    line-height: 1.5;
    color: #f10073;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .unga-wrapper.redesign .requestForm {
    max-width: unset;
    border-radius: 8px;
  }
  .unga-wrapper.redesign .requestForm h3 {
    font-size: 38px;
    margin-bottom: 34px;
  }
  .unga-wrapper.redesign .requestForm h3:not(:first-child) {
    margin-top: 14px;
  }
  .unga-wrapper.redesign .requestForm h5.partner-text {
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 25px;
    color: #000000;
  }
  .unga-wrapper.redesign .check {
    margin-top: 20px;
  }
  .unga-wrapper.redesign .check__box {
    margin: 0 39px 9px 0;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
  }
  .unga-wrapper.redesign .check__box span {
    border: 2px solid #717171;
  }
  .unga-wrapper.redesign .check-label {
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
    transform: translateY(-2px);
  }
  .unga-wrapper.redesign .check-label .purple-link {
    display: inline-block;
    color: #d261db;
    text-decoration: underline !important;
    letter-spacing: 1;
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
  }
  .unga-wrapper.redesign .check__box input:checked ~ span {
    background-color: #d261db;
  }
  .unga-wrapper.redesign .cap-width {
    width: 100%;
    max-width: 920px;
    margin: auto;
  }
  .unga-wrapper.redesign .cap-width.slightly-wider {
    max-width: 1000px;
  }
  .unga-wrapper.redesign .header-img {
    width: 100%;
    padding: 10px;
    max-width: 600px;
  }
  .unga-wrapper.redesign .header-text-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .unga-wrapper.redesign .header-text-container .header-text {
    color: #000;
    font-size: 27px;
    line-height: 33px;
    padding: 12px;
  }
  .unga-wrapper.redesign .hero__bg {
    top: -80px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 25px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 10px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .unga-wrapper.redesign .hero__bg img {
    width: 100%;
    object-fit: cover;
    object-position: unset;
  }
  .unga-wrapper.redesign .hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 80px;
  }
  .unga-wrapper.redesign .hero-btns .hero-btn .btn-std.pink {
    background-color: #ff2f92;
    font-size: 28px;
    color: #fff;
    padding-top: 25px;
    padding-bottom: 15px;
    text-align: center;
  }
  .unga-wrapper.redesign .hero-btns .hero-btn .btn-std.pink:hover {
    background-color: #df2e84;
  }
  .unga-wrapper.redesign .hero-btns .hero-btn:first-child {
    margin-right: auto;
  }
  @media (max-width: 767px) {
    .unga-wrapper.redesign .show-mob {
      display: flex;
    }
    .unga-wrapper.redesign .hide-mob {
      display: none;
    }
    .unga-wrapper.redesign .hero__inner h1 {
      font-size: 74px;
    }
    .unga-wrapper.redesign .hero-btns {
      flex-direction: column;
      justify-content: center;
      margin-top: 68px;
      margin-bottom: 150px;
    }
    .unga-wrapper.redesign .hero-btns .hero-btn {
      margin: auto;
      margin-bottom: 70px;
    }
    .unga-wrapper.redesign .hero-btns .hero-btn:first-child {
      margin: auto;
    }
    .unga-wrapper.redesign .header-text-container .header-text {
      margin-top: 50px;
    }
    .unga-wrapper.redesign .options-list .option-container {
      width: 92%;
      margin: auto;
      margin-bottom: 36px;
      /* margin-left: 20px; */
      /* margin-right: 20px; */
    }
    .unga-wrapper.redesign label.input__outer.sm:not(:first-child) {
      margin-top: unset !important;
    }
    .unga-wrapper.redesign label.input__outer.sm {
      margin-bottom: 42px;
    }
    .unga-wrapper.redesign .requestForm h3 {
      margin-top: 40px !important;
    }
    .unga-wrapper.redesign .requestForm .input__outer.sm {
      width: 100%;
    }
    .unga-wrapper.redesign .requestForm .input__outer h5 {
      margin-bottom: 3px;
      font-size: 20px;
    }
    .unga-wrapper.redesign .check {
      margin-top: 55px;
    }
    .unga-wrapper.redesign .check-label {
      font-size: 20px;
      line-height: 1.3;
    }
    .unga-wrapper.redesign .requestForm h5.partner-text {
      margin-bottom: 61px;
      margin-top: 88px;
      font-size: 26px;
      line-height: 1.3;
    }
    .unga-wrapper.redesign .form-invalid-msg {
      font-size: 20px;
      margin-top: 24px;
      text-align: center;
    }
    .unga-wrapper.redesign .requestForm {
      padding: 34px 26px 32px;
    }
    .unga-wrapper.redesign .options-list .option-container {
      width: 100%;
    }
    .unga-wrapper.redesign .options-list .option-container .option-header {
      padding-left: 26px;
      padding-right: 26px;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header
      .text.prefix {
      font-size: 30px;
    }
    .unga-wrapper.redesign .options-list .option-container .option-header.logo {
      flex-direction: column;
      justify-content: center;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .left {
      width: 100%;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .right {
      width: 50%;
      padding-top: 26px;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header.logo
      .right
      .logo-title {
      font-size: 22px;
      margin-bottom: 5px;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .unga-wrapper.redesign .options-list .option-container .option-body {
      padding-left: 26px;
      padding-right: 26px;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container
      .date-time-container
      .date {
      margin-bottom: 15px;
      text-align: left !important;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-body
      .upper-container
      .date-time-container:last-child {
      margin-top: 30px;
    }
  }

  .unga-wrapper .partners-section {
    background-color: #fff;
    width: 100%;
    padding: 26px;
  }
  .unga-wrapper .partners-section .inner {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
  }
  .unga-wrapper .partners-section .inner .header-img {
    width: 100%;
    max-width: 740px;
    margin: auto;
    // background-image: url(/public/images/unga/rect-grad.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px;
    text-align: center;
    color: #fff;
    height: 102px;
    border-radius: 12px;
    margin-bottom: 16px;
  }
  .unga-wrapper .partners-section .inner .header-img .header-img-text {
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 12px;
  }
  .unga-wrapper .partners-section .inner .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust the gap between images */
    justify-content: center;
    /* Center the images horizontally */
    padding: 10px;
    margin-bottom: 50px;
  }
  .unga-wrapper .partners-section .inner .gallery .gallery-item {
    width: calc((100% / 3) - 10px);
    /* Adjust width and margin for each image */
    background-color: #fff;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
  @media (max-width: 768px) {
    .unga-wrapper .partners-section .inner .gallery .gallery-item {
      width: calc((100% / 2) -10px);
      /* Adjust for smaller screens */
    }
  }
  @media (max-width: 480px) {
    .unga-wrapper .partners-section .inner .gallery .gallery-item {
      width: 100%;
      /* Stack images on very small screens */
    }
  }

  .unga-wrapper .footer-section {
    background-color: #8748f0;
    padding: 110px;
    padding-left: 46px;
    padding-right: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #fff;
    margin-top: 30px;
  }
  .unga-wrapper .footer-section .footer-header {
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 12px;
  }
  .unga-wrapper .footer-section .footer-sub-header {
    margin: auto;
    font-size: 22px;
    letter-spacing: 0.5px;
    font-weight: 300;
  }

  .ces-header {
    position: absolute;
    background-color: #002b4900;
  }

  @media (max-width: 768px) {
    .unga-wrapper.redesign .option-header {
      border-radius: 0 !important;
    }
    .unga-wrapper.redesign .options-list .option-container {
      border-radius: 0;
    }
    .unga-wrapper.redesign .options-list .option-container:not(.top) {
      border: 6px solid #9e4fea;
    }
    .unga-wrapper.redesign .options-list .option-container .option-header {
      border-radius: 0 !important;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header
      .text {
      font-size: 38px;
    }
    .unga-wrapper.redesign
      .options-list
      .option-container
      .option-header
      .text.prefix {
      font-size: 28px;
    }
    .unga-wrapper.redesign .hero__inner h1 {
      font-size: 59px;
      text-align: center;
    }
    .unga-wrapper.redesign .hero-container .hero-logos {
      flex-direction: column;
      justify-content: center;
    }
    .unga-wrapper.redesign .hero-container .hero-logos .lm-logo {
      margin: auto;
    }
    .unga-wrapper.redesign .hero-container .hero-logos .sis-logo,
    .unga-wrapper.redesign .hero-container .hero-logos .un-logo {
      margin: auto;
      margin-top: 25px;
    }
    .unga-wrapper.redesign .requestForm h5.partner-text {
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) and (max-width: 767px) {
    .unga-wrapper.redesign .options-list .option-container {
      /* margin-left: 20px; */
      /* margin-right: 20px; */
    }
    .unga-wrapper.redesign .options-list .option-container .option-header {
      border-radius: 0 !important;
    }
  }

  @media (max-width: 768px) {
    .unga-wrapper .partners-section .inner .header-img {
      height: fit-content;
    }
    .unga-wrapper .partners-section .inner .header-img .header-img-text {
      line-height: 1;
    }
    .unga-wrapper .footer-section .footer-header {
      font-size: 34px;
      line-height: 1;
    }
  }

  .israel-dealportal.new .req-item .req-item-content {
    padding-top: 27px;
    padding-bottom: 24px;
    padding-right: 27px;
  }

  .israel-dealportal.new .startup-row.top {
    padding-bottom: 13px;
    border-bottom: 1px solid #00000029;
    margin-bottom: 10px;
  }

  .israel-dealportal.new .startup-row {
    margin-left: 13px;
  }

  .israel-dealportal.new .check__box.adj {
    background-color: #fff;
    margin: 0;
  }

  .israel-dealportal.new .check {
    margin-top: 20px;
  }

  .israel-dealportal.new .check__box {
    margin: 0 39px 9px 0;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    cursor: pointer;
  }
  .israel-dealportal.new .check__box span {
    border: 2px solid #ff38c2;
  }

  .israel-dealportal.new .check-label {
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
    transform: translateY(-2px);
  }

  .israel-dealportal.new .check__box input:checked ~ span {
    background-color: #ff38c2;
  }

  .israel-dealportal.new .header-text {
    margin-top: 40px;
  }

  .israel-dealportal.new .request {
    padding-top: 0;
  }

  .israel-dealportal.new .option-container .outer-container {
    display: flex;
    flex-direction: row;
  }
  .israel-dealportal.new
    .option-container
    .outer-container
    .checkbox-container {
    background-image: url(https://i.imgur.com/CC3UZue.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-top: 34px;
  }

  @media (max-width: 767px) {
    .israel-dealportal.new .hero__inner h1 {
      font-size: 60px;
    }
    .israel-dealportal.new .startup-field {
      text-align: left;
      margin-top: 10px;
      font-weight: 700;
    }
    .israel-dealportal.new .startup-img-col {
      justify-content: start;
      justify-self: flex-start;
      padding-bottom: 16px;
    }
    .israel-dealportal.new .startup-img {
      max-width: 200px;
    }
  }

  .israel-dealportal .req-item .req-item-content {
    padding-top: 17px;
    padding-bottom: 11px;
    padding-right: 27px;
  }

  .israel-dealportal .startup-info-container {
    display: flex;
    flex-direction: column;
  }

  .israel-dealportal .startup-img-col {
    align-self: center;
    justify-content: end;
    justify-self: flex-end;
    display: flex;
  }

  .israel-dealportal .startup-img {
    width: 100%;
    align-self: center;
    max-width: 142px;
    text-align: right;
  }

  .israel-dealportal .short-desc-container {
    display: flex;
    align-items: end;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
  }

  .israel-dealportal .row-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .israel-dealportal .startup-row {
    margin-left: 7px;
    display: flex;
    align-items: start;
    margin-bottom: 4px;
  }
  .israel-dealportal .startup-row.top {
    align-items: end;
    padding-bottom: 4px;
    border-bottom: 1px solid #00000029;
    margin-bottom: 10px;
  }
  .israel-dealportal .startup-row a {
    color: #0000ff;
    line-height: 1.4;
  }

  .israel-dealportal .startup-field {
    text-align: right;
  }

  .lead-gen-wrapper {
    font-family: "Open Sans", sans-serif;
    color: #999999;
    background: #fff;
  }
  .lead-gen-wrapper .auto__container {
    position: relative;
    max-width: 1020px;
    margin: 0 auto;
    padding: 0 40px;
  }
  .lead-gen-wrapper .title {
    font-size: 42px;
    text-align: center;
    width: fit-content;
    margin: auto;
    padding-top: 120px;
    padding-bottom: 360px;
  }
  .lead-gen-wrapper h1 {
    font-size: 50px;
    line-height: 140%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #222222;
  }
  .lead-gen-wrapper h1 span {
    color: #00a6cb;
  }
  .lead-gen-wrapper h2 {
    font-size: 45px;
    line-height: 133%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #222222;
  }
  .lead-gen-wrapper h2 span {
    color: #ff4444;
  }
  .lead-gen-wrapper h3 {
    font-size: 30px;
    line-height: 150%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #222222;
  }
  .lead-gen-wrapper h3.big {
    font-size: 34px;
  }
  .lead-gen-wrapper h3 span {
    color: #00a6cb;
  }
  .lead-gen-wrapper h4 {
    font-size: 24px;
    line-height: 145%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #fff;
  }
  .lead-gen-wrapper h5 {
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #222222;
  }
  .lead-gen-wrapper h6 {
    font-size: 14px;
    line-height: 120%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-transform: none;
  }
  .lead-gen-wrapper h6.big {
    font-size: 16px;
  }
  .lead-gen-wrapper h6.sup {
    color: #999999;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
  }
  .lead-gen-wrapper p {
    font-size: 14px;
    line-height: 172%;
    font-weight: 400;
  }
  .lead-gen-wrapper p.big {
    font-size: 16px;
    line-height: 187%;
  }
  .lead-gen-wrapper p.sm {
    font-size: 12px;
    line-height: 120%;
  }
  .lead-gen-wrapper .input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .input__outer {
    display: flex;
    flex-direction: column;
  }
  .lead-gen-wrapper .input__outer p {
    font-family: "Montserrat", sans-serif;
    color: #999;
    margin-bottom: 8px;
  }
  .lead-gen-wrapper .input input,
  .lead-gen-wrapper .input textarea {
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 100%;
    font-family: "Montserrat", sans-serif;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #eeeeee;
    padding: 15px;
    color: #222222;
    resize: none;
    background-color: #fff;
  }
  .lead-gen-wrapper .button {
    font-size: 20px;
    line-height: 100%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    padding: 26px 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: 0.3s ease;
    text-transform: uppercase;
    border-radius: 3px;
  }
  .lead-gen-wrapper .button.primary {
    background-color: #002b49;
    background-color: #00a6cb;
  }
  .lead-gen-wrapper .button.primary:hover {
    opacity: 1;
    background-color: #44bfff;
  }
  .lead-gen-wrapper .button.secondary {
    background-color: #e88e0c;
    background-color: #00a6cb;
  }
  .lead-gen-wrapper .button.secondary:hover {
    opacity: 1;
    background-color: #44bfff;
  }

  @keyframes bounce {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
  .lead-gen-wrapper .market {
    background-color: #f9f9f9;
  }
  .lead-gen-wrapper .marketTop {
    padding: 100px 0 225px;
    border-bottom: 1px solid #eeeeee;
  }
  .lead-gen-wrapper .marketTop__inner-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 820px;
    margin: 0 auto;
  }
  .lead-gen-wrapper .marketTop__inner-title p {
    max-width: 540px;
  }
  .lead-gen-wrapper .marketTop__inner-logo {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
  .lead-gen-wrapper .marketTop__inner-logo img {
    width: 100%;
  }
  .lead-gen-wrapper .marketTop__inner h1 {
    margin-bottom: 30px;
  }
  .lead-gen-wrapper .marketMain {
    margin-top: -135px;
  }
  .lead-gen-wrapper .marketMain__inner-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lead-gen-wrapper .marketMain__inner-col {
    display: flex;
    flex-direction: column;
    width: calc(25% - 10px);
    max-width: 230px;
  }
  .lead-gen-wrapper .marketItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    text-align: center;
  }
  .lead-gen-wrapper .marketItem:last-child {
    margin: 0;
  }
  .lead-gen-wrapper .marketItem__icon {
    width: 48px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .lead-gen-wrapper .marketItem__icon.sm {
    width: 30px;
  }
  .lead-gen-wrapper .marketItem__icon img {
    width: 100%;
  }
  .lead-gen-wrapper .marketItem h6 {
    margin-bottom: 16px;
    color: #222222;
    font-weight: 700;
  }
  .lead-gen-wrapper .marketForm {
    background-color: #fff;
    box-shadow: 0px 0px 5.94px 0.06px rgba(34, 34, 34, 0.1),
      0px -4px 0px 0px rgba(232, 142, 12, 0.004);
    border-radius: 10px;
    padding: 56px 30px 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 20px);
    max-width: 380px;
    position: relative;
    box-sizing: border-box;
    border-top: 4px solid #00a6cb;
  }
  .lead-gen-wrapper .marketForm__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    opacity: 0.04;
  }
  .lead-gen-wrapper .marketForm__bg img {
    width: 166%;
    transform: translateY(8%);
  }
  .lead-gen-wrapper .marketForm__title {
    width: 100%;
    text-align: center;
    z-index: 2;
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 50px;
    box-sizing: border-box;
  }
  .lead-gen-wrapper .marketForm__title::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30px;
    height: 1px;
    background-color: #00a6cb;
    content: "";
    transform: translateX(-50%);
  }
  .lead-gen-wrapper .marketForm__title h4 {
    margin-bottom: 10px;
    color: #222222;
  }
  .lead-gen-wrapper .marketForm__main {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  .lead-gen-wrapper .marketForm__arrow {
    width: 35%;
    max-width: 140px;
    position: absolute;
    bottom: 7%;
    right: calc(100% + 35px);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  .lead-gen-wrapper .marketForm__arrow.right {
    right: unset;
    left: calc(100% + 35px);
  }
  .lead-gen-wrapper .marketForm__arrow img {
    width: 100%;
  }
  .lead-gen-wrapper .marketForm .input__outer {
    margin: 0 0 22px 0;
    width: 100%;
    max-width: 285px;
  }
  .lead-gen-wrapper .marketForm .button {
    width: 100%;
    max-width: 285px;
  }
  .lead-gen-wrapper .partners {
    padding: 75px 0;
    background-color: #f9f9f9;
  }
  .lead-gen-wrapper .partners.big {
    padding: 130px 0 70px;
    background-color: #fff;
  }
  .lead-gen-wrapper .partners__inner-row {
    display: flex;
    justify-content: space-between;
  }
  .lead-gen-wrapper .partners__inner p {
    text-align: center;
    margin-bottom: 35px;
  }
  .lead-gen-wrapper .partnersItem {
    width: calc(16.6% - 10px);
    max-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .partnersItem img {
    width: 90%;
    max-width: 92px;
  }
  .lead-gen-wrapper .partnersItem img.big {
    max-width: 105px;
  }
  .lead-gen-wrapper .partnersItem img.sm {
    width: 85%;
    max-width: 84px;
  }
  .lead-gen-wrapper .banner {
    padding: 160px 0;
    position: relative;
  }
  .lead-gen-wrapper .banner__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #49aee2 0%, #0061af 100%);
    opacity: 0.9;
    pointer-events: none;
  }
  .lead-gen-wrapper .banner__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 390px;
    margin: 0 auto;
  }
  .lead-gen-wrapper .banner__inner button {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease;
    margin-right: 24px;
  }
  .lead-gen-wrapper .banner__inner button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
    animation: bounce 1s infinite;
    border-radius: 50%;
  }
  .lead-gen-wrapper .banner__inner button::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 85%;
    height: 85%;
    background-color: transparent;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
    animation: bounce 1s infinite;
    border-radius: 50%;
  }
  .lead-gen-wrapper .banner__inner button span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 18px solid #00a6cb;
    border-bottom: 10px solid transparent;
    transform: translate(-35%, -50%);
  }
  .lead-gen-wrapper .review {
    padding: 80px 0;
    background-color: #f9f9f9;
    position: relative;
  }
  .lead-gen-wrapper .review.big {
    padding: 120px 0;
  }
  .lead-gen-wrapper .review.big .review__inner-title::before {
    display: none;
  }
  .lead-gen-wrapper .review.big .review__inner h3 {
    color: #fff;
  }
  .lead-gen-wrapper .review.big .review__inner h6 {
    color: #fff;
  }
  .lead-gen-wrapper .review.big .reviewItem {
    border: unset;
  }
  .lead-gen-wrapper .review.big .reviewItem__stars {
    width: 77px;
  }
  .lead-gen-wrapper .review.big .reviewItem h6 {
    color: #222222;
  }
  .lead-gen-wrapper .review__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #002b49 0%, #4777f4 100%);
    opacity: 0.9;
    pointer-events: none;
  }
  .lead-gen-wrapper .review__inner-title {
    max-width: 515px;
    margin: 0 auto 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .lead-gen-wrapper .review__inner-title.big {
    margin-bottom: 95px;
  }
  .lead-gen-wrapper .review__inner-title::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30px;
    height: 1px;
    background-color: #0087cb;
    content: "";
    transform: translateX(-50%);
  }
  .lead-gen-wrapper .review__inner-title p {
    max-width: 430px;
    margin-bottom: 20px;
  }
  .lead-gen-wrapper .review__inner-row {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }
  .lead-gen-wrapper .review__inner h6 {
    margin-bottom: 15px;
  }
  .lead-gen-wrapper .review__inner h3 {
    margin-bottom: 20px;
  }
  .lead-gen-wrapper .review__inner h3:last-child {
    margin: 0;
  }
  .lead-gen-wrapper .review__inner .slick-track {
    display: flex !important;
    align-items: stretch;
  }
  .lead-gen-wrapper .review__inner .slick-slide {
    margin: 0 10px;
    height: unset !important;
  }
  .lead-gen-wrapper .reviewItem {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5.94px 0.06px rgba(34, 34, 34, 0.1),
      0px -4px 0px 0px rgba(0, 135, 203, 0.004);
    padding: 46px 30px;
    border-top: 4px solid #00a6cb;
    display: block !important;
  }
  .lead-gen-wrapper .reviewItem__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .lead-gen-wrapper .reviewItem__avatar {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bbbbbb;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 22px;
  }
  .lead-gen-wrapper .reviewItem__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .lead-gen-wrapper .reviewItem__stars {
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .reviewItem__stars img {
    width: 100%;
  }
  .lead-gen-wrapper .reviewItem h6 {
    color: #222222;
    margin-bottom: 8px;
  }
  .lead-gen-wrapper .reviewItem p {
    margin: 22px 0;
  }
  .lead-gen-wrapper .reviewItem p.sm {
    margin: 0;
  }
  .lead-gen-wrapper .offer {
    padding: 100px 0;
  }
  .lead-gen-wrapper .offer.big {
    padding: 160px 0;
  }
  .lead-gen-wrapper .offer.big .offer__inner h3 span,
  .lead-gen-wrapper .offer.big .offer__inner h2 span {
    color: #ff4444;
  }
  .lead-gen-wrapper .offer__inner-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .lead-gen-wrapper .offer__inner-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 20px);
    max-width: 955px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1;
  }
  .lead-gen-wrapper .offer__inner-bg img {
    width: 100%;
  }
  .lead-gen-wrapper .offer__inner h3,
  .lead-gen-wrapper .offer__inner h2 {
    margin-bottom: 30px;
  }
  .lead-gen-wrapper .offer__inner p {
    max-width: 540px;
    margin-bottom: 40px;
  }
  .lead-gen-wrapper .copy {
    background-color: #f9f9f9;
    border-top: 1px solid #eeeeee;
    padding: 30px 0 35px;
  }
  .lead-gen-wrapper .copy.big {
    padding: 50px 0;
    background-color: #ecf9ff;
    border-color: #e2eef4;
  }
  .lead-gen-wrapper .copy.big .copy__inner p a {
    color: #002b49;
  }
  .lead-gen-wrapper .copy.big .copy__inner p a:hover {
    opacity: 1;
    color: #ff4444;
  }
  .lead-gen-wrapper .copy__inner p {
    text-align: center;
    line-height: 100%;
  }
  .lead-gen-wrapper .copy__inner p a {
    font-size: inherit;
    display: inline-block;
    color: #00a6cb;
    line-height: inherit;
  }
  .lead-gen-wrapper .copy__inner p a:hover {
    opacity: 1;
    color: #ff4444;
  }
  .lead-gen-wrapper .feature {
    padding: 75px 0 120px;
  }
  .lead-gen-wrapper .feature__inner-title {
    text-align: center;
    max-width: 520px;
    margin: 0 auto 90px;
  }
  .lead-gen-wrapper .feature__inner-title h6 {
    margin-bottom: 20px;
  }
  .lead-gen-wrapper .feature__inner-row {
    display: flex;
    justify-content: space-between;
  }
  .lead-gen-wrapper .featureItem {
    width: calc(33.3% - 8px);
    max-width: 300px;
    transition: 0.3s ease;
    padding: 48px 30px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-bottom: 4px solid transparent;
  }
  .lead-gen-wrapper .featureItem:hover {
    box-shadow: 0px 0px 35px 15px rgba(34, 34, 34, 0.05),
      0px 4px 0px 0px rgba(68, 191, 255, 0.004);
    border-color: #00a6cb;
  }
  .lead-gen-wrapper .featureItem__icon {
    width: 75px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
  }
  .lead-gen-wrapper .featureItem__icon.big {
    width: 83px;
  }
  .lead-gen-wrapper .featureItem__icon img {
    width: 100%;
  }
  .lead-gen-wrapper .featureItem h5 {
    margin-bottom: 24px;
  }
  .lead-gen-wrapper .service {
    padding: 120px 0;
    background-color: #f5fbff;
  }
  .lead-gen-wrapper .service.light {
    background-color: #fff;
  }
  .lead-gen-wrapper .service__inner {
    display: flex;
    justify-content: space-between;
  }
  .lead-gen-wrapper .service__inner-image {
    width: calc(48% - 10px);
    max-width: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .service__inner-image img {
    width: 100%;
  }
  .lead-gen-wrapper .service__inner-content {
    width: calc(52% - 10px);
    max-width: 450px;
  }
  .lead-gen-wrapper .service__inner-row {
    display: flex;
    justify-content: space-between;
    padding-top: 55px;
  }
  .lead-gen-wrapper .service__inner h3 {
    margin: 28px 0;
  }
  .lead-gen-wrapper .serviceItem {
    width: calc(33.3% - 5px);
    max-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
    border-bottom: 4px solid transparent;
    position: relative;
    text-align: center;
  }
  .lead-gen-wrapper .serviceItem:hover {
    background-color: #fff;
    box-shadow: 0px 0px 35px 15px rgba(34, 34, 34, 0.05),
      0px 4px 0px 0px rgba(68, 191, 255, 0.004);
    border-color: #43b8f5;
  }
  .lead-gen-wrapper .serviceItem:hover span {
    opacity: 1;
  }
  .lead-gen-wrapper .serviceItem__icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .lead-gen-wrapper .serviceItem__icon.sm {
    width: 40px;
  }
  .lead-gen-wrapper .serviceItem__icon img {
    width: 100%;
  }
  .lead-gen-wrapper .serviceItem__number {
    font-size: 35px;
    line-height: 100%;
    color: #002b49;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    position: relative;
  }
  .lead-gen-wrapper .serviceItem__number sup {
    font-size: 15px;
    line-height: 100%;
    position: absolute;
    top: 0;
    left: calc(100% + 2px);
    transform: translateY(-2px);
  }
  .lead-gen-wrapper .serviceItem h6 {
    color: #222222;
  }
  .lead-gen-wrapper .serviceItem span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff4444;
    border-radius: 2px;
    color: #fff;
    font-size: 9px;
    line-height: 100%;
    text-transform: uppercase;
    padding: 5px 6px;
    transition: 0.3s ease;
    opacity: 0;
  }
  .lead-gen-wrapper .contact {
    position: relative;
    padding: 190px 0 70px;
    background-color: #ecf9ff;
  }
  .lead-gen-wrapper .contact__bg {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    pointer-events: none;
  }
  .lead-gen-wrapper .contact__bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .lead-gen-wrapper .contact__inner-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lead-gen-wrapper .contactItem {
    width: calc(50% - 8px);
    text-align: left;
  }
  .lead-gen-wrapper .contactItem h5 {
    margin-bottom: 20px;
  }
  .lead-gen-wrapper .contactItem p a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  .lead-gen-wrapper .contactItem p a:hover {
    opacity: 1;
    color: #ff4444;
  }
  .lead-gen-wrapper .magic {
    position: relative;
    padding: 50px 0 0;
  }
  .lead-gen-wrapper .magic__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #002b49 0%, #4777f4 100%);
    opacity: 0.9;
    pointer-events: none;
  }
  .lead-gen-wrapper .magic__bg img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .lead-gen-wrapper .magic__inner {
    color: #fff;
  }
  .lead-gen-wrapper .magic__inner-row {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
  }
  .lead-gen-wrapper .magic__inner-logo {
    width: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .magic__inner-logo img {
    width: 100%;
  }
  .lead-gen-wrapper .magic__inner-content {
    width: calc(55% - 10px);
    max-width: 490px;
    padding-top: 50px;
  }
  .lead-gen-wrapper .magic__inner-content > p {
    margin-bottom: 34px;
  }
  .lead-gen-wrapper .magic__inner-play {
    display: flex;
    align-items: center;
  }
  .lead-gen-wrapper .magic__inner-play button {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease;
    margin-right: 22px;
  }
  .lead-gen-wrapper .magic__inner-play button:hover::before {
    animation: bounce 1s infinite;
  }
  .lead-gen-wrapper .magic__inner-play button:hover::after {
    animation: bounce 1s infinite;
  }
  .lead-gen-wrapper .magic__inner-play button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .lead-gen-wrapper .magic__inner-play button::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 85%;
    height: 85%;
    background-color: transparent;
    border: 1px solid #fff;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .lead-gen-wrapper .magic__inner-play button span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 12px solid #222222;
    border-bottom: 7px solid transparent;
    transform: translate(-40%, -50%);
  }
  .lead-gen-wrapper .magic__inner-play p {
    font-size: 11px;
    line-height: 18px;
  }
  .lead-gen-wrapper .magic__inner h2 {
    color: #fff;
    margin-bottom: 24px;
  }
  .lead-gen-wrapper .magicForm {
    background-color: #fff;
    box-shadow: 0px 0px 5.94px 0.06px rgba(34, 34, 34, 0.1);
    border-radius: 10px;
    position: relative;
    padding: 60px 35px 0 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .lead-gen-wrapper .magicForm__outer {
    width: calc(45% - 10px);
    max-width: 385px;
    position: relative;
    padding-bottom: 35px;
  }
  .lead-gen-wrapper .magicForm__outer h6 {
    font-size: 15px;
    line-height: 100%;
    position: absolute;
    width: 100%;
    top: calc(100% + 15px);
    left: 50%;
    font-family: "Segoe Print", sans-serif;
    color: #222222;
    font-weight: 700;
    transform: translateX(-50%);
    text-align: center;
  }
  .lead-gen-wrapper .magicForm__outer h6 b {
    color: #ff4444;
    font-weight: 700;
    margin-left: 4px;
  }
  .lead-gen-wrapper .magicForm__outer h6 span {
    position: absolute;
    right: 6%;
    bottom: 50%;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lead-gen-wrapper .magicForm__outer h6 span img {
    width: 100%;
  }
  .lead-gen-wrapper .magicForm__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 0;
    pointer-events: none;
  }
  .lead-gen-wrapper .magicForm__bg img {
    width: 100%;
    min-height: 180px;
    border-radius: 15px;
  }
  .lead-gen-wrapper .magicForm__title {
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 2;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 120px;
    box-sizing: border-box;
  }
  .lead-gen-wrapper .magicForm__title::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30px;
    height: 1px;
    background-color: #fff;
    content: "";
    transform: translateX(-50%);
  }
  .lead-gen-wrapper .magicForm__title h4 {
    color: #fff;
    margin-bottom: 6px;
  }
  .lead-gen-wrapper .magicForm__main {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .lead-gen-wrapper .magicForm .input__outer {
    margin: 0 0 22px 0;
    width: 100%;
    max-width: 285px;
  }
  .lead-gen-wrapper .magicForm .input__outer:last-of-type {
    margin: 0;
  }
  .lead-gen-wrapper .magicForm .button {
    transform: translateY(50%);
    margin-top: -10px;
    width: 100%;
    max-width: 285px;
  }

  @media (max-width: 1180px) {
    .lead-gen-wrapper h1 {
      font-size: 45px;
    }
    .lead-gen-wrapper h2 {
      font-size: 40px;
    }
  }

  @media (max-width: 1024px) {
    .lead-gen-wrapper h1 {
      font-size: 40px;
    }
    .lead-gen-wrapper h2 {
      font-size: 35px;
    }
    .lead-gen-wrapper .button {
      padding: 23px 44px;
      font-size: 18px;
    }
    .lead-gen-wrapper .marketTop {
      padding: 70px 0 160px;
    }
    .lead-gen-wrapper .marketTop__inner-logo {
      width: 100px;
      margin-bottom: 40px;
    }
    .lead-gen-wrapper .marketTop__inner h1 {
      margin-bottom: 20px;
    }
    .lead-gen-wrapper .marketMain {
      margin-top: -100px;
    }
    .lead-gen-wrapper .marketForm {
      padding: 40px 20px 30px 20px;
    }
    .lead-gen-wrapper .marketForm__title {
      margin-bottom: 35px;
    }
    .lead-gen-wrapper .marketForm__arrow {
      bottom: 5%;
      right: calc(100% + 20px);
    }
    .lead-gen-wrapper .marketForm__arrow.right {
      left: calc(100% + 20px);
    }
    .lead-gen-wrapper .marketForm .input__outer {
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .marketForm .input__outer:last-of-type {
      margin-bottom: 22px;
    }
    .lead-gen-wrapper .marketItem {
      margin-bottom: 40px;
    }
    .lead-gen-wrapper .marketItem__icon {
      margin-bottom: 20px;
    }
    .lead-gen-wrapper .marketItem h6 {
      margin-bottom: 10px;
    }
    .lead-gen-wrapper .magic {
      padding-top: 40px;
    }
    .lead-gen-wrapper .magic__inner-row {
      padding-top: 70px;
    }
    .lead-gen-wrapper .magic__inner-content {
      padding-top: 30px;
    }
    .lead-gen-wrapper .magicForm {
      padding: 40px 30px 0 30px;
    }
    .lead-gen-wrapper .magicForm__outer h6 span {
      right: 2%;
    }
    .lead-gen-wrapper .magicForm__title {
      margin-bottom: 100px;
    }
    .lead-gen-wrapper .magicForm .input__outer {
      margin-bottom: 12px;
    }
    .lead-gen-wrapper .partners.big {
      padding: 100px 0 75px;
    }
    .lead-gen-wrapper .service {
      padding: 100px 0;
    }
    .lead-gen-wrapper .review {
      padding: 60px 0;
    }
    .lead-gen-wrapper .review.big {
      padding: 90px 0;
    }
    .lead-gen-wrapper .review__inner-title {
      margin-bottom: 40px;
    }
    .lead-gen-wrapper .review__inner-title.big {
      margin-bottom: 60px;
    }
    .lead-gen-wrapper .review__inner-title h6 {
      margin-bottom: 8px;
    }
    .lead-gen-wrapper .review__inner h3 {
      margin-bottom: 10px;
    }
    .lead-gen-wrapper .feature {
      padding: 60px 0 100px;
    }
    .lead-gen-wrapper .offer.big {
      padding: 130px 0;
    }
  }

  @media (max-width: 840px) {
    .lead-gen-wrapper .auto__container {
      padding: 0 30px;
    }
    .lead-gen-wrapper h1 {
      font-size: 35px;
    }
    .lead-gen-wrapper h2 {
      font-size: 30px;
    }
    .lead-gen-wrapper h3 {
      font-size: 25px;
    }
    .lead-gen-wrapper h3.big {
      font-size: 30px;
    }
    .lead-gen-wrapper h4 {
      font-size: 22px;
    }
    .lead-gen-wrapper h5 {
      font-size: 18px;
    }
    .lead-gen-wrapper .button {
      padding: 20px 34px;
      font-size: 16px;
    }
    .lead-gen-wrapper .input input {
      padding: 12px;
    }
    .lead-gen-wrapper .magic {
      padding-top: 40px;
    }
    .lead-gen-wrapper .magic__inner-row {
      padding-top: 50px;
    }
    .lead-gen-wrapper .magic__inner-content {
      padding-top: 20px;
    }
    .lead-gen-wrapper .magic__inner-content > p {
      margin-bottom: 20px;
    }
    .lead-gen-wrapper .magic__inner h2 {
      margin-bottom: 12px;
    }
    .lead-gen-wrapper .magicForm {
      padding: 30px 24px 0 24px;
    }
    .lead-gen-wrapper .magicForm__bg img {
      min-height: 120px;
    }
    .lead-gen-wrapper .magicForm__outer h6 {
      font-size: 13px;
      top: calc(100% + 5px);
    }
    .lead-gen-wrapper .magicForm__outer h6 span {
      right: 1%;
      width: 12px;
    }
    .lead-gen-wrapper .magicForm__title {
      margin-bottom: 70px;
      padding-bottom: 15px;
    }
    .lead-gen-wrapper .magicForm .input__outer {
      margin-bottom: 12px;
    }
    .lead-gen-wrapper .partners.big {
      padding: 75px 0;
    }
    .lead-gen-wrapper .service {
      padding: 80px 0;
    }
    .lead-gen-wrapper .service__inner-content {
      width: calc(57% - 7px);
    }
    .lead-gen-wrapper .service__inner-image {
      width: calc(43% - 7px);
    }
    .lead-gen-wrapper .service__inner-row {
      padding-top: 30px;
    }
    .lead-gen-wrapper .service__inner h3 {
      margin: 15px 0;
    }
    .lead-gen-wrapper .serviceItem {
      padding: 25px 6px 20px;
    }
    .lead-gen-wrapper .feature {
      padding: 50px 0 80px;
    }
    .lead-gen-wrapper .feature__inner-title {
      margin-bottom: 60px;
    }
    .lead-gen-wrapper .featureItem {
      padding: 30px 15px;
    }
    .lead-gen-wrapper .featureItem__icon {
      width: 60px;
      height: 70px;
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .featureItem__icon.big {
      width: 65px;
    }
    .lead-gen-wrapper .featureItem h5 {
      margin-bottom: 12px;
    }
    .lead-gen-wrapper .banner {
      padding: 130px 0;
    }
    .lead-gen-wrapper .banner__inner {
      justify-content: center;
    }
    .lead-gen-wrapper .banner__inner button {
      margin-right: 40px;
    }
    .lead-gen-wrapper .review {
      padding: 50px 0;
    }
    .lead-gen-wrapper .review.big {
      padding: 60px 0;
    }
    .lead-gen-wrapper .review__inner-row {
      margin: 0 -5px;
    }
    .lead-gen-wrapper .review__inner-title {
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .review__inner-title.big {
      margin-bottom: 40px;
    }
    .lead-gen-wrapper .review__inner .slick-slide {
      margin: 0 5px;
    }
    .lead-gen-wrapper .reviewItem {
      padding: 30px 15px;
    }
    .lead-gen-wrapper .offer {
      padding: 80px 0;
    }
    .lead-gen-wrapper .offer.big {
      padding: 100px 0;
    }
    .lead-gen-wrapper .offer__inner-bg {
      width: 110%;
    }
    .lead-gen-wrapper .contact {
      padding: 140px 0 60px;
    }
    .lead-gen-wrapper .contact__inner h5 {
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .copy {
      padding: 30px 0;
    }
    .lead-gen-wrapper .copy.big {
      padding: 40px 0;
    }
  }

  @media (max-width: 700px) {
    .lead-gen-wrapper h1 {
      font-size: 30px;
    }
    .lead-gen-wrapper h2 {
      font-size: 25px;
    }
    .lead-gen-wrapper h3 {
      font-size: 25px;
    }
    .lead-gen-wrapper h3.big {
      font-size: 25px;
    }
    .lead-gen-wrapper h4 {
      font-size: 20px;
    }
    .lead-gen-wrapper h5 {
      font-size: 16px;
    }
    .lead-gen-wrapper h6 {
      font-size: 12px;
    }
    .lead-gen-wrapper h6.big {
      font-size: 14px;
    }
    .lead-gen-wrapper p {
      font-size: 12px;
    }
    .lead-gen-wrapper p.big {
      font-size: 14px;
    }
    .lead-gen-wrapper p.sm {
      font-size: 11px;
    }
    .lead-gen-wrapper .marketTop {
      padding: 50px 0 130px;
    }
    .lead-gen-wrapper .marketTop__inner-logo {
      width: 80px;
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .marketTop__inner h1 {
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .marketMain {
      margin-top: -80px;
      padding-bottom: 20px;
    }
    .lead-gen-wrapper .marketMain__inner-row {
      flex-wrap: wrap;
    }
    .lead-gen-wrapper .marketMain__inner-col {
      width: calc(50% - 15px);
      max-width: unset;
    }
    .lead-gen-wrapper .marketForm {
      width: 100%;
      order: -1;
      align-self: center;
      margin: 0 auto 40px;
    }
    .lead-gen-wrapper .marketForm__bg img {
      width: 150%;
    }
    .lead-gen-wrapper .marketForm__arrow {
      display: none;
    }
    .lead-gen-wrapper .magic {
      padding-top: 30px;
    }
    .lead-gen-wrapper .magic__inner-logo {
      width: 120px;
    }
    .lead-gen-wrapper .magic__inner-row {
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
    }
    .lead-gen-wrapper .magic__inner-content {
      width: 100%;
      max-width: unset;
      padding: 0;
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .magicForm {
      padding: 60px 30px 0 30px;
    }
    .lead-gen-wrapper .magicForm__outer {
      width: 100%;
      padding: 0;
    }
    .lead-gen-wrapper .magicForm__outer h6 {
      font-size: 12px;
      top: calc(100% + 36px);
    }
    .lead-gen-wrapper .magicForm__outer h6 span {
      right: 3%;
    }
    .lead-gen-wrapper .magicForm__title {
      margin-bottom: 100px;
    }
    .lead-gen-wrapper .service {
      padding: 50px 0 60px;
    }
    .lead-gen-wrapper .service__inner {
      flex-direction: column;
      align-items: center;
    }
    .lead-gen-wrapper .service__inner-content {
      width: 100%;
      max-width: unset;
      text-align: center;
    }
    .lead-gen-wrapper .service__inner-image {
      width: 100%;
      max-width: 400px;
      margin-bottom: 30px;
      order: -1;
    }
    .lead-gen-wrapper .service__inner-row {
      padding-top: 40px;
      max-width: 500px;
      margin: 0 auto;
    }
    .lead-gen-wrapper .service__inner h3 {
      margin: 15px 0;
    }
    .lead-gen-wrapper .serviceItem {
      padding: 30px;
    }
    .lead-gen-wrapper .feature {
      padding: 30px 0 40px;
    }
    .lead-gen-wrapper .feature__inner-title {
      margin-bottom: 40px;
    }
    .lead-gen-wrapper .feature__inner-row {
      flex-direction: column;
      align-items: center;
    }
    .lead-gen-wrapper .featureItem {
      width: 100%;
      max-width: 360px;
      padding: 24px 20px;
      margin-bottom: 20px;
    }
    .lead-gen-wrapper .featureItem:last-child {
      margin: 0;
    }
    .lead-gen-wrapper .featureItem__icon {
      width: 60px;
      height: 70px;
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .featureItem__icon.big {
      width: 65px;
    }
    .lead-gen-wrapper .featureItem h5 {
      margin-bottom: 12px;
    }
    .lead-gen-wrapper .partners {
      padding: 50px 0;
    }
    .lead-gen-wrapper .partners.big {
      padding: 100px 0 50px;
    }
    .lead-gen-wrapper .partners__inner p {
      margin-bottom: 24px;
    }
    .lead-gen-wrapper .banner {
      padding: 100px 0;
    }
    .lead-gen-wrapper .banner__inner {
      justify-content: center;
    }
    .lead-gen-wrapper .banner__inner button {
      width: 60px;
      height: 60px;
      margin-right: 30px;
    }
    .lead-gen-wrapper .banner__inner button span {
      border-width: 8px 0 8px 12px;
    }
    .lead-gen-wrapper .offer {
      padding: 60px 0;
    }
    .lead-gen-wrapper .offer.big {
      padding: 80px 0;
    }
    .lead-gen-wrapper .offer__inner h2,
    .lead-gen-wrapper .offer__inner h3 {
      margin-bottom: 20px;
    }
    .lead-gen-wrapper .offer__inner p {
      margin-bottom: 24px;
    }
    .lead-gen-wrapper .contact {
      padding: 80px 0 40px;
    }
    .lead-gen-wrapper .contact__inner-row {
      flex-wrap: wrap;
      justify-content: center;
    }
    .lead-gen-wrapper .contact__inner h5 {
      margin-bottom: 10px;
    }
    .lead-gen-wrapper .contactItem {
      width: calc(50% - 40px);
      margin: 20px;
    }
    .lead-gen-wrapper .copy {
      padding: 24px 0;
    }
    .lead-gen-wrapper .copy.big {
      padding: 24px 0;
    }
  }

  @media (max-width: 540px) {
    .lead-gen-wrapper .auto__container {
      padding: 0 20px;
    }
    .lead-gen-wrapper h1 {
      font-size: 25px;
    }
    .lead-gen-wrapper h4 {
      font-size: 18px;
    }
    .lead-gen-wrapper .button {
      padding: 18px 30px;
      font-size: 14px;
    }
    .lead-gen-wrapper .magic__inner-content {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
    .lead-gen-wrapper .magic__inner-play {
      text-align: start;
      justify-content: center;
    }
    .lead-gen-wrapper .magic__inner-play button {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }
    .lead-gen-wrapper .marketTop {
      padding: 40px 0 100px;
    }
    .lead-gen-wrapper .marketTop__inner-logo {
      width: 60px;
      margin-bottom: 24px;
    }
    .lead-gen-wrapper .marketTop__inner h1 {
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .marketMain {
      margin-top: -70px;
    }
    .lead-gen-wrapper .marketMain__inner-col {
      width: calc(50% - 10px);
    }
    .lead-gen-wrapper .marketForm {
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .marketItem {
      margin-bottom: 30px;
    }
    .lead-gen-wrapper .marketItem__icon {
      width: 38px;
      height: 40px;
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .marketItem__icon.sm {
      width: 24px;
    }
    .lead-gen-wrapper .service {
      padding: 40px 0 50px;
    }
    .lead-gen-wrapper .service__inner-row {
      padding-top: 30px;
    }
    .lead-gen-wrapper .serviceItem {
      padding: 20px 10px;
    }
    .lead-gen-wrapper .partners {
      padding: 35px 0;
    }
    .lead-gen-wrapper .partners.big {
      padding: 100px 0 35px;
    }
    .lead-gen-wrapper .partners__inner-row {
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    .lead-gen-wrapper .partners__inner p {
      margin-bottom: 15px;
    }
    .lead-gen-wrapper .partnersItem {
      width: calc(33.3% - 20px);
      margin: 10px;
    }
    .lead-gen-wrapper .banner {
      padding: 80px 0;
    }
    .lead-gen-wrapper .banner__inner {
      justify-content: center;
    }
    .lead-gen-wrapper .banner__inner button {
      width: 50px;
      height: 50px;
      margin-right: 24px;
    }
    .lead-gen-wrapper .banner__inner button span {
      border-width: 8px 0 8px 12px;
    }
    .lead-gen-wrapper .review {
      padding: 40px 0;
    }
    .lead-gen-wrapper .review.big {
      padding: 50px 0;
    }
    .lead-gen-wrapper .review__inner-row {
      margin: 0 -5px;
    }
    .lead-gen-wrapper .review__inner-title.big {
      margin-bottom: 24px;
    }
    .lead-gen-wrapper .reviewItem {
      padding: 30px 15px;
    }
    .lead-gen-wrapper .offer {
      padding: 35px 0 40px;
    }
    .lead-gen-wrapper .offer.big {
      padding: 35px 0 40px;
    }
    .lead-gen-wrapper .offer__inner-bg {
      width: 140%;
    }
    .lead-gen-wrapper .offer__inner h3 span,
    .lead-gen-wrapper .offer__inner h2 span {
      display: block;
    }
    .lead-gen-wrapper .contact {
      padding: 40px 0 30px;
    }
    .lead-gen-wrapper .contact__bg {
      display: none;
    }
    .lead-gen-wrapper .contact__inner-row {
      flex-direction: column;
      align-items: center;
      justify-content: unset;
    }
    .lead-gen-wrapper .contact__inner h5 {
      margin-bottom: 10px;
    }
    .lead-gen-wrapper .contactItem {
      width: 100%;
      margin: 0 0 20px 0;
    }
    .lead-gen-wrapper .contactItem:last-child {
      margin: 0;
    }
    .lead-gen-wrapper .copy {
      padding: 25px 0;
    }
    .lead-gen-wrapper .copy.big {
      padding: 25px 0;
    }
  }

  /*# sourceMappingURL=main.css.map */
}
